<div
  class="app-container"
  [class.app-container--open]="open"
  (click)="closeDialog(false)"
>
  <div *ngIf="open" class="box" (click)="$event.stopPropagation()">
    <h3 class="box__title">{{ title }}</h3>
    <p class="box__description">{{ description }}</p>
    <div class="box__actions">
      <button
        (click)="closeDialog(true)"
        class="box__confirm-button button-cta-2 button-cta-2--red"
      >
        {{ confirmButton }}
      </button>
      <button
        (click)="closeDialog(false)"
        class="box__cancel-button button-plain-2"
      >
        Cancelar
      </button>
    </div>
  </div>
</div>
