import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmActionDialogService {
  onOpenCloseDialog: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor() {}

  openDialog(data: {
    title: string;
    description?: string;
    confirmButton?: string;
    confirmCallback: (confirm: boolean) => void;
  }) {
    this.onOpenCloseDialog.next({ ...data });
  }

  confirmDialog() {}
}
