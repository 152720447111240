<div class="content h-full">
  <ng-container [ngSwitch]="pageLoaded">
    <ng-container *ngSwitchCase="'loading'">
      <app-loader-feedback size="large"></app-loader-feedback>
    </ng-container>

    <ng-container *ngSwitchCase="'error'">
      <div class="flex h-full items-center justify-center">
        <app-error-feedback></app-error-feedback>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'idle'">
      <ng-container *ngIf="userClient; else noClient">
        <ng-container
          *ngIf="
            (professionalSubscriptionType$ | async) === 'InteccElite';
            else noEliteSubscription
          "
        >
          <div class="flex items-center text-2xl">
            <i class="fa-solid fa-bullseye mr-3 text-xl text-intecc2"></i>
            <p class="">Valoración</p>
          </div>
          <p class="text-lg text-neutral-400">Su estado anatómico actual</p>

          <div class="mb-4 mt-2 space-y-4 md:flex md:space-x-4 md:space-y-0">
            <button
              (click)="openAvatarModal()"
              class="text mx-auto flex w-full items-center justify-center rounded-lg bg-inteccDark px-4 py-3 font-semibold text-white shadow-sm"
            >
              <i class="fas fa-user"></i>
              <div class="ml-4 font-bold">Avatar de valoración</div>
            </button>
          </div>

          <div class="mb-8 h-[612px]">
            <app-avatar
              [isModal]="false"
              [openAvatarSideModal]="openAvatarModal.bind(this)"
              [isUserClientView]="true"
            ></app-avatar>
          </div>
          <div id="tests-section">
            <app-items-list
              primaryColorClass="primary-2"
              secondaryColorClass="primary-9"
              [itemsObservable$]="clientTestsList$"
              [canUseFeature]="true"
              featureNotAvailableText="Empieza tu prueba gratuita para acceder a las valoraciones"
              [handleGenerateReport]="generateReportForValorations"
              [isUserClientView]="true"
            ></app-items-list>
          </div>
          <!-- Inicio sección de Correctivos-->
          <div class="flex items-center text-2xl">
            <i class="fa-regular fa-life-ring mr-3 text-xl text-intecc2"></i>
            <p class="">Correctivos</p>
          </div>
          <div id="corrective-sessions-section">
            <p class="mb-2 text-lg text-neutral-400">Ejercicios recomendados</p>
            <app-items-list
              primaryColorClass="primary-2"
              secondaryColorClass="primary-9"
              [itemsObservable$]="clientCorrectiveSessionsList$"
              [canUseFeature]="true"
              featureNotAvailableText="Empieza tu prueba gratuita para acceder a los ejercicios correctivos"
              [handleGenerateReport]="generateReportClientCorrectiveSessions"
              [isUserClientView]="true"
            ></app-items-list>
          </div>
        </ng-container>
        <ng-template #noEliteSubscription>
          <app-no-elite-subscription-feedback></app-no-elite-subscription-feedback>
        </ng-template>
      </ng-container>

      <ng-template #noClient>
        <div
          class="flex h-full flex-col items-center justify-center text-center"
        >
          <i class="fa-solid fa-user-slash mb-4 text-6xl text-gray-400"></i>
          <div class="text-lg font-semibold text-gray-600">
            Debes unirte a un profesional para ver tus registros de cliente
          </div>
          <!-- <button
            (click)="joinRandomProfessional()"
            class="hover:bg-intecc1 mt-4 rounded-lg bg-intecc2 px-4 py-2 text-white"
          >
            Unirme a un profesional
          </button> -->
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
