import { Injectable } from '@angular/core';
import { Observable, combineLatest, map, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Exercise, EXERCISES_PATH } from '../models/exercise.model';
import {
  ExercisesSection,
  GlobalExercisesSection,
} from '../models/exercises-section.model';
import { EXERCISESSECTIONS_PATH } from '../models/exercises-section.model';
import { FirestoreService } from './firestore.service';
import { safeToPromise } from '../utils/utils';
import { AuthService } from './auth.service';
import { UserClientService } from './user-client.service';

@Injectable({
  providedIn: 'root',
})
export class ExerciseService {
  private allExercises$: Observable<Exercise[]>;
  private exercisesDetailsMap: { [key: string]: Exercise } = {};

  constructor(
    private firestore: FirestoreService,
    private authService: AuthService,
    private userClientService: UserClientService,
  ) {
    this.allExercises$ = this.authService.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        }

        const getUserExercises$ = (userId: string) =>
          combineLatest([
            this.getGlobalExercises(),
            this.getUserExercises(userId),
          ]).pipe(
            map(([globalExercises, userExercises]) => [
              ...globalExercises,
              ...userExercises,
            ]),
          );

        return user.role === 'client'
          ? this.userClientService.professional$.pipe(
              switchMap((professional) =>
                professional ? getUserExercises$(professional.uid) : of([]),
              ),
            )
          : getUserExercises$(user.uid);
      }),
    );
  }

  async updateSections(section: ExercisesSection | GlobalExercisesSection) {
    await this.firestore.update(
      `${EXERCISESSECTIONS_PATH}/${section.id}`,
      section,
    );
    return true;
  }

  // Get all sections with exercises for a user
  async getUserSections(userId: string) {
    const sections: ExercisesSection[] = await safeToPromise(
      this.firestore
        .colWithIds$<ExercisesSection>(`${EXERCISESSECTIONS_PATH}`, (ref) =>
          ref.where('owner', '==', userId),
        )
        .pipe(take(1)),
    );
    if (sections.length === 0) {
      return undefined;
    }
    return sections[0];
  }

  async getGlobalSections() {
    const sections: GlobalExercisesSection[] = await safeToPromise(
      this.firestore
        .colWithIds$<GlobalExercisesSection>(
          `${EXERCISESSECTIONS_PATH}`,
          (ref) => ref.where('isGlobal', '==', true),
        )
        .pipe(take(1)),
    );
    if (sections.length === 0) {
      return undefined;
    }
    return sections[0];
  }

  // Get library exercises
  getGlobalExercises(): Observable<Exercise[]> {
    return this.firestore.colWithIds$<Exercise>(`${EXERCISES_PATH}`, (ref) =>
      ref.where('isGlobal', '==', true).orderBy('updatedAt', 'desc'),
    );
  }

  // Get user exercises
  getUserExercises(userId: string): Observable<Exercise[]> {
    return this.firestore.colWithIds$<Exercise>(`${EXERCISES_PATH}`, (ref) =>
      ref.where('owner', '==', userId).orderBy('updatedAt', 'desc'),
    );
  }

  getExerciseById(exerciseId: string) {
    return this.allExercises$.pipe(
      map((exercises) =>
        exercises.find((exercise) => exercise.id === exerciseId),
      ),
      switchMap((exercise) =>
        exercise
          ? of(exercise)
          : this.firestore.doc$<Exercise>(`${EXERCISES_PATH}/${exerciseId}`),
      ),
    );
  }

  async getSectionsOrCreateSections(owner: string) {
    let section = await this.getUserSections(owner);
    if (!section) {
      section = { owner, sections: [] };
      await this.firestore.add(`${EXERCISESSECTIONS_PATH}`, section);
      section = await this.getUserSections(owner);
    }
    return section;
  }

  // Create a new exercise with custom ID
  async createExercise(
    userId: string,
    exercise: Partial<Exercise>,
  ): Promise<string> {
    const exerciseId = exercise.id || this.firestore.createId(); // Use provided ID or generate new one
    const { isGlobal, owner = userId, ...restExercise } = exercise;
    const exerciseToSave = {
      ...restExercise,
      id: exerciseId,
      createdAt: new Date(),
      updatedAt: new Date(),
      ...(isGlobal ? { isGlobal: true } : { owner: owner }),
    };
    await this.firestore.set(`${EXERCISES_PATH}/${exerciseId}`, exerciseToSave);
    return exerciseId;
  }

  // Update an exercise
  updateExercise(
    exerciseId: string,
    exercise: Partial<Exercise>,
  ): Promise<void> {
    return this.firestore.doc(`${EXERCISES_PATH}/${exerciseId}`).update({
      ...exercise,
      updatedAt: new Date(),
    });
  }

  // Delete an exercise
  deleteExercise(exerciseId: string): Promise<void> {
    return this.firestore.doc(`${EXERCISES_PATH}/${exerciseId}`).delete();
  }

  private async loadExerciseDetails(exerciseId: string) {
    if (!this.exercisesDetailsMap[exerciseId]) {
      this.exercisesDetailsMap[exerciseId] = await safeToPromise(
        this.getExerciseById(exerciseId),
      );
    }
    return this.exercisesDetailsMap[exerciseId];
  }

  async loadMultipleExerciseDetails(exerciseIds: string[]) {
    const promises = exerciseIds.map((id) => this.loadExerciseDetails(id));
    await Promise.all(promises);
  }

  getExerciseDetailsFromCache(exerciseId: string) {
    return this.exercisesDetailsMap[exerciseId];
  }

  clearExerciseDetailsCache() {
    if (Object.keys(this.exercisesDetailsMap).length > 100) {
      this.exercisesDetailsMap = {};
    }
  }
}
