<div
  *ngIf="mode === 'select' && theme === 'normal'"
  class="py-2 px-3 rounded-md  bg-neutral-100  relative text-base {{
    tableMode ? 'text-sm mr-2' : 'text-base bg-neutral-100'
  }}"
>
  <div
    (click)="open()"
    class="grid cursor-pointer grid-cols-fr-auto items-center"
  >
    <p *ngIf="selectedIndex !== -1 && selectedIndex !== undefined; else empty">
      {{ options[selectedIndex] }}
    </p>

    <ng-template #empty>
      <p>&nbsp;</p>
    </ng-template>
    <i class="fas fa-angle-down"></i>
  </div>
  <div
    class="absolute  w-full bg-white shadow-lg rounded-md z-10 overflow-hidden {{
      openToTop ? 'bottom-0 left-0' : 'top-0 left-0'
    }} "
    [class.hidden]="!isOpen"
  >
    <p
      class="cursor-pointer px-3 py-2 transition-all duration-200 hover:bg-inteccLight"
      (click)="selectOption(-1)"
    >
      -
    </p>
    <p
      *ngFor="let option of options; index as i"
      (click)="selectOption(i)"
      class="cursor-pointer px-3 py-2 transition-all duration-200 hover:bg-inteccLight"
    >
      {{ option }}
    </p>
  </div>
</div>
<div *ngIf="mode === 'text' && theme === 'normal'" class="w-full">
  <input
    [readonly]="!isEditable"
    (change)="onTextChanged($event)"
    [value]="text"
    class="w-full py-2 px-3 rounded-md   relative  {{
      tableMode ? 'text-sm' : 'text-base bg-neutral-100'
    }} {{ addStyles }}"
    type="text"
  />
</div>

<div *ngIf="mode === 'number' && theme === 'normal'">
  <input
    [readonly]="!isEditable"
    (change)="onTextChanged($event)"
    [value]="text"
    class="w-full py-2 px-3 rounded-md   relative  {{
      tableMode ? 'text-sm' : 'text-base bg-neutral-100'
    }} {{ addStyles }}"
    type="number"
    [min]="0"
  />
</div>

<div
  *ngIf="mode === 'select' && theme === 'avatar'"
  class="relative rounded-md px-3 py-2 text-base"
>
  <div
    (click)="open()"
    class="grid cursor-pointer grid-cols-fr-auto items-center"
  >
    <p *ngIf="selectedIndex !== -1 && selectedIndex !== undefined; else empty">
      {{ options[selectedIndex] }}
    </p>

    <ng-template #empty>
      <p>Sin valorar</p>
    </ng-template>
    <i class="fas fa-angle-down"></i>
  </div>
  <div
    class="absolute  w-full bg-inteccDark shadow-lg rounded-md z-10 overflow-hidden {{
      openToTop ? 'bottom-0 left-0' : 'top-0 left-0'
    }} "
    [class.hidden]="!isOpen"
  >
    <p
      class="cursor-pointer px-3 py-2 transition-all duration-200 hover:bg-intecc"
      (click)="selectOption(-1)"
    >
      -
    </p>
    <p
      *ngFor="let option of options; index as i"
      (click)="selectOption(i)"
      class="cursor-pointer px-3 py-2 transition-all duration-200 hover:bg-intecc"
    >
      {{ option }}
    </p>
  </div>
</div>
