import { Injectable } from '@angular/core';
import { ModalComponent } from '../components/modal/modal.component';
import { take } from 'rxjs/operators';
import {
  SideModalComponent,
  SideModalConfig,
} from '../components/side-modal/side-modal.component';
import { BottomModalComponent } from '../components/bottom-modal/bottom-modal.component';
import { SideModalLeftComponent } from '../components/side-modal-left/side-modal-left.component';
import { Modals } from '../static/modals.static';
import { safeToPromise } from '../utils/utils';

export type ModalKey = keyof typeof Modals;
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalComponent: ModalComponent;
  sideModalComponents: Map<string, SideModalComponent> = new Map();
  sideModalLeftComponent: SideModalLeftComponent;
  bottomModalComponent: BottomModalComponent;

  constructor() {}

  async openModal(modalId: ModalKey, data?: any) {
    this.modalComponent.loadModal(modalId, data);
    const result = await safeToPromise(
      this.modalComponent.onClose.pipe(take(1)),
    );
    return result;
  }

  async openLateralModal(
    modalId: ModalKey,
    data?: any,
    config?: SideModalConfig,
  ) {
    const sideModal =
      modalId === 'exercises'
        ? this.sideModalComponents.get('exercises')
        : this.sideModalComponents.get('rest');

    if (!sideModal) {
      console.error(`No side modal found for ${modalId}`);
      return;
    }

    sideModal.openModal(modalId, data, config);
    const result = await safeToPromise(sideModal.onClose.pipe(take(1)), {
      timeoutMs: undefined,
    });
    return result;
  }

  async openSideLeftModal(modalId: ModalKey, data?: any) {
    this.sideModalLeftComponent.openModal(modalId, data);
    const result = await safeToPromise(
      this.sideModalLeftComponent.onClose.pipe(take(1)),
      {
        timeoutMs: undefined,
      },
    );
    return result;
  }

  async openBottomModal(modalId: ModalKey, data?: any) {
    this.bottomModalComponent.openModal(modalId, data);
    const result = await safeToPromise(
      this.bottomModalComponent.onClose.pipe(take(1)),
      {
        timeoutMs: undefined,
      },
    );
    return result;
  }

  registerSideModal(id: string, component: SideModalComponent) {
    this.sideModalComponents.set(id, component);
  }
}
