import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Client } from 'src/app/models/client.model';
import {
  CorrectiveSessionSection,
  CorrectiveSessionSectionItem,
} from 'src/app/models/corrective-session-section.model';
import { AuthService } from 'src/app/services/auth.service';
import { ClientsService } from 'src/app/services/clients.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CorrectiveSessionsService } from 'src/app/services/corrective-sessions.service';
import {
  CorrectiveSessionProtocolDef,
  getCorrectiveSessionProtocolInitials,
} from 'src/shared/corrective-session-definition.outside';
import { Observable, lastValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-corrective-sessions-menu',
  templateUrl: './corrective-sessions-menu.component.html',
  styleUrls: ['./corrective-sessions-menu.component.scss'],
})
export class CorrectiveSessionsMenuComponent implements OnInit {
  constructor(
    private correctiveSessionsService: CorrectiveSessionsService,
    private auth: AuthService,
    private router: Router,
    private clientService: ClientsService,
    private snackbarService: SnackbarService,
    private nav: NavigationService,
  ) {}

  @Input() isOpen = false;

  @Output() createClientCorrectiveSessionEvent =
    new EventEmitter<CorrectiveSessionProtocolDef>();

  correctiveSessionProtocols: Array<CorrectiveSessionProtocolDef> = [];
  correctiveSessionProtocols$: Observable<Array<CorrectiveSessionProtocolDef>>;

  correctiveSessionProtocolsWithoutSection: Array<CorrectiveSessionProtocolDef> =
    [];

  sections: CorrectiveSessionSection;

  getCorrectiveSessionProtocolInitials = getCorrectiveSessionProtocolInitials;

  selectedCorrectiveSessionProtocolToMove = '';
  moveCorrectiveSessionProtocolDialogOpen = false;

  editSectionNameSection: CorrectiveSessionSectionItem;
  editSectionNameOpen = false;
  editSectionNameText = 'asdg';

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;

  sharedUrl: string;

  creatingSessionFromUrl = false;
  currentClient: Client = null;

  ngOnInit(): void {
    this.init();
  }

  async init() {
    const user = await this.auth.getUser();

    this.clientService.currentClient$.subscribe((client) => {
      this.currentClient = client;
    });

    this.correctiveSessionProtocols$ =
      await this.correctiveSessionsService.getCorrectiveSessionProtocols$(
        user.uid,
      );

    this.sections =
      await this.correctiveSessionsService.getSectionsOrCreateSections(
        user.uid,
      );

    this.correctiveSessionProtocols = await lastValueFrom(
      this.correctiveSessionProtocols$.pipe(take(1)),
    );

    this.correctiveSessionProtocols$.subscribe((protocols) => {
      this.correctiveSessionProtocols = protocols;
      this.updateCorrectiveSessionProtocolsWihtoutSection();
    });

    this.clearnSections();
    this.updateCorrectiveSessionProtocolsWihtoutSection();
  }

  clearnSections() {
    this.sections.sections.forEach(
      (s) =>
        (s.correctiveSessionProtocolIds = s.correctiveSessionProtocolIds.filter(
          (p) => !!this.getCorrectiveSessionProtocolById(p),
        )),
    );
  }

  updateCorrectiveSessionProtocolsWihtoutSection() {
    if (!this.sections?.sections) {
      this.correctiveSessionProtocolsWithoutSection =
        this.correctiveSessionProtocols;
    }
    this.correctiveSessionProtocolsWithoutSection =
      this.correctiveSessionProtocols.filter(
        (p) =>
          !this.sections.sections.find(
            (item) =>
              !!item.correctiveSessionProtocolIds.find((id) => id === p.id),
          ),
      );
  }

  close() {
    history.back();
  }

  async createCorrectiveSessionProtocol() {
    const user = await this.auth.getUser();
    const correctiveSessionrotocolId =
      await this.correctiveSessionsService.createCorrectiveSessionProtocol(
        user,
      );
    this.router.navigate([
      'corrective-session-protocol',
      correctiveSessionrotocolId,
    ]);
  }

  getCorrectiveSessionProtocolById(id: string) {
    if (!this.correctiveSessionProtocols) {
      return undefined;
    }
    const result = this.correctiveSessionProtocols.find((p) => p.id === id);
    return result;
  }

  editCorrectiveSessionProtocol(
    correctiveSessionProtocol: CorrectiveSessionProtocolDef,
    event,
  ) {
    event.stopPropagation();
    this.router.navigate([
      'corrective-session-protocol',
      correctiveSessionProtocol.id,
    ]);
  }

  /** from a corrective session protocol, creates a corrective session for a client */
  createClientCorrectiveSession(
    correctiveSessionProtocol: CorrectiveSessionProtocolDef,
  ) {
    this.createClientCorrectiveSessionEvent.next(correctiveSessionProtocol);
  }

  addSection() {
    if (!this.sections) {
      return;
    }
    if (!this.sections.sections) {
      this.sections.sections = [];
    }
    this.sections.sections.push({
      name: 'Sección',
      correctiveSessionProtocolIds: [],
    });
    this.updateSections();
  }

  moveSectionUp(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === 0) {
      return;
    }
    const hold = this.sections.sections[sectionIndex - 1];
    this.sections.sections[sectionIndex - 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveSectionDown(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === this.sections.sections.length - 1) {
      return;
    }
    const hold = this.sections.sections[sectionIndex + 1];
    this.sections.sections[sectionIndex + 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveCorrectiveSessionProtocolUp(
    sectionIndex: number,
    correctiveSessionProtocolIndex: number,
    event,
  ) {
    event.stopPropagation();
    const section =
      this.sections.sections[sectionIndex].correctiveSessionProtocolIds;
    console.log(section);

    if (correctiveSessionProtocolIndex === 0) {
      return;
    }
    const hold = section[correctiveSessionProtocolIndex - 1];
    section[correctiveSessionProtocolIndex - 1] =
      section[correctiveSessionProtocolIndex];
    section[correctiveSessionProtocolIndex] = hold;
    this.updateSections();
  }

  moveCorrectiveSessionProtocolDown(
    sectionIndex: number,
    correctiveSessionProtocolIndex: number,
    event,
  ) {
    console.log(
      'sectionIndex: ',
      sectionIndex,
      ' correctiveSessionProtocolIndex: ',
      correctiveSessionProtocolIndex,
    );
    event.stopPropagation();
    const section =
      this.sections.sections[sectionIndex].correctiveSessionProtocolIds;
    if (correctiveSessionProtocolIndex === section.length - 1) {
      return;
    }
    const hold = section[correctiveSessionProtocolIndex + 1];
    section[correctiveSessionProtocolIndex + 1] =
      section[correctiveSessionProtocolIndex];
    section[correctiveSessionProtocolIndex] = hold;
    this.updateSections();
  }

  openMoveCorrectiveSessionProtocolDialog(
    protocol: CorrectiveSessionProtocolDef,
    event,
  ) {
    event.stopPropagation();
    if (
      !this.sections ||
      !this.sections.sections ||
      this.sections.sections.length === 0
    ) {
      return;
    }
    this.selectedCorrectiveSessionProtocolToMove = protocol.id;
    this.moveCorrectiveSessionProtocolDialogOpen = true;
  }

  moveToSection(section: CorrectiveSessionSectionItem) {
    this.sections.sections.forEach((s) => {
      s.correctiveSessionProtocolIds = s.correctiveSessionProtocolIds.filter(
        (id) => id !== this.selectedCorrectiveSessionProtocolToMove,
      );
    });
    section.correctiveSessionProtocolIds.push(
      this.selectedCorrectiveSessionProtocolToMove,
    );
    this.moveCorrectiveSessionProtocolDialogOpen = false;
    this.updateSections();
  }

  closeMoveToSection() {
    this.moveCorrectiveSessionProtocolDialogOpen = false;
  }

  updateSections() {
    this.updateCorrectiveSessionProtocolsWihtoutSection();
    this.correctiveSessionsService.updateSections(this.sections);
  }

  openRenameSection(section: CorrectiveSessionSectionItem, event) {
    event.stopPropagation();
    this.editSectionNameOpen = true;
    this.editSectionNameSection = section;
    this.editSectionNameText = section.name;
  }

  endRenameSection() {
    this.editSectionNameSection.name = this.editSectionNameText;
    this.editSectionNameOpen = false;
    this.updateSections();
  }

  deleteSection(index: number) {
    this.sections.sections.splice(index, 1);
    this.updateSections();
  }

  async createClientCorrectiveSessionFromSharedUrl() {
    this.creatingSessionFromUrl = true;
    const sessionId =
      await this.correctiveSessionsService.createClientCorrectiveSessionFromSharedUrl(
        this.sharedUrl,
        this.currentClient,
      );
    if (!sessionId) {
      this.snackbarService.error('No se pudo crear la sesión');
      return;
    }
    this.nav.goToClientCorrectiveSession(sessionId);
    this.creatingSessionFromUrl = false;
  }
}
