<div class="relative h-full w-full">
  <div
    *ngIf="protocol"
    class="max-w-4xl mx-auto rounded-xl bg-white ring-1 ring-black/5 shadow-sm transition-all duration-200 {{
      testEditMode ? 'protocol-edit-card-hidden' : ''
    }}"
  >
    <div
      class="flex flex-col items-center px-8 py-6 md:flex-row md:justify-between"
    >
      <h1 class="mb-3 text-center text-3xl font-bold md:mb-0">
        {{
          protocol.name
            ? protocol.name
            : 'Protocolo My
                VAF'
        }}
      </h1>
      <div class="flex flex-col items-center md:flex-row">
        <app-two-state-button
          class="mb-3 md:mb-0 md:mr-4"
          (buttonClick)="duplicate()"
          [loading]="deleting"
          [text]="'Duplicar'"
          [type]="'normal'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-copy'"
        ></app-two-state-button>
        <app-two-state-button
          (buttonClick)="saveAndExit()"
          [loading]="saving"
          [text]="'Guardar'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-save'"
        ></app-two-state-button>
      </div>
    </div>
    <div class="mb-2">
      <div class="border-t border-neutral-200"></div>
    </div>
    <div class="px-8 py-6">
      <div class="mb-4">
        <h2 class="mb-2 text-2xl">Título</h2>
        <app-input [(value)]="protocol.name"></app-input>
      </div>
      <div class="grid grid-cols-1 gap-y-3">
        <div>
          <h2 class="mb-2 text-2xl">Detalles</h2>
          <app-input
            [type]="'textarea'"
            class="md:col-span-2"
            [fieldName]="'Descripción'"
            [(value)]="protocol.description"
          ></app-input>
        </div>
        <div class="flex items-center justify-between">
          <p class="mb-1 font-bold text-neutral-600">Imagen y enlace</p>
          <app-button
            (clickEvent)="addDescriptionFixedImage()"
            [text]="'Añadir '"
            [icon]="'fas fa-plus'"
          ></app-button>
        </div>
      </div>
      <div
        cdkDropList
        class="test-list"
        (cdkDropListDropped)="dropDescriptionFixedImage($event)"
      >
        <div
          class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
          *ngFor="let image of protocol.fixedImages; let i = index"
          cdkDrag
        >
          <div class="flex w-full items-center justify-between">
            <div class="transition-all duration-200">
              <!-- <app-input class="mb-2 block" [fieldName]="'Título'" [(value)]="image.title"></app-input> -->
              <div
                class="grid w-full grid-cols-1 items-end gap-x-5 gap-y-3 md:grid-cols-4"
              >
                <app-input
                  [readonly]="true"
                  [fieldName]="'URL de la imagen'"
                  [(value)]="image.downloadUrl"
                  [button]="getImageUrlCopyButton(image.downloadUrl)"
                >
                </app-input>
                <app-input [fieldName]="'Título'" [(value)]="image.title">
                </app-input>
                <app-input
                  [fieldName]="'Enlace (opcional)'"
                  [(value)]="image.link"
                >
                </app-input>
                <app-button
                  (clickEvent)="uploadDescriptionFixedImage(i)"
                  [text]="'Subir imagen '"
                  [icon]="'fas fas fa-upload'"
                >
                </app-button>
              </div>
              <div
                class="grid grid-cols-1 items-end gap-x-5 gap-y-3 pt-5 md:grid-cols-2"
              >
                <!-- <app-input [placeholder]="'https://...'" [fieldName]="'Enlace (opcional)'" [(value)]="image.link">
                </app-input> -->
                <!-- <app-simple-dropdown [fieldName]="'Ubicación en el informe PDF'" [options]="imagePositionOptions"
                  [(selectedValue)]="image.position"></app-simple-dropdown> -->
              </div>
            </div>
            <div
              (click)="deleteDescriptionFixedImage(i, $event)"
              class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
            >
              <i class="far fa-trash-alt"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="my-8">
        <h2 class="mb-2 text-2xl">Enlace para compartir</h2>
        <app-input
          class="md:col-span-2"
          [readonly]="true"
          [value]="getShareURL()"
          [button]="copySharedUrlButton"
        ></app-input>
      </div>
      <div class="mb-4 flex items-center justify-between">
        <h1 class="text-2xl">Secciones</h1>
        <app-button
          (clickEvent)="addTest()"
          [text]="'Añadir'"
          [icon]="'fas fa-plus'"
        ></app-button>
      </div>
      <div
        cdkDropList
        cdkDropListLockAxis="y"
        class="test-list"
        (cdkDropListDropped)="drop($event)"
      >
        <div
          (click)="openTest(i)"
          class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-3 focus:shadow-xl"
          *ngFor="let test of protocol.tests; let i = index"
          cdkDrag
        >
          <div class="flex w-full items-center justify-between">
            <span>{{ test.name }}</span>
            <div class="flex">
              <div
                (click)="duplicateTest(i, $event)"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
              >
                <i class="far fa-copy"></i>
              </div>
              <div
                (click)="deleteTest(i, $event)"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
              >
                <i class="far fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-10"></div>
      <div class="flex justify-end">
        <app-two-state-button
          class="mr-5"
          (buttonClick)="deleteAndExit()"
          [loading]="deleting"
          [text]="'Eliminar'"
          [type]="'danger'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-trash-alt'"
        ></app-two-state-button>
        <app-two-state-button
          (buttonClick)="saveAndExit()"
          [loading]="saving"
          [text]="'Guardar'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-save'"
        ></app-two-state-button>
      </div>
    </div>
  </div>
  <div
    class="w-full h-full absolute top-0 left-0 {{
      testEditMode ? '' : 'hidden'
    }}"
  >
    <div
      *ngIf="selectedTest"
      class="animated fadeIn mx-auto max-w-4xl rounded-xl bg-white shadow-sm ring-1 ring-black/5"
    >
      <div
        class="flex flex-col items-center px-8 py-6 md:flex-row md:justify-between"
      >
        <h1 class="mb-3 text-3xl font-bold md:mb-0">{{ selectedTest.name }}</h1>
        <app-two-state-button
          (buttonClick)="saveTestAndBack()"
          [loading]="saving"
          [text]="'Guardar'"
          [loadingText]="'Guardando...'"
          [faIconCode]="'far fa-save'"
        ></app-two-state-button>
      </div>
      <div class="mb-2">
        <div class="border-t border-neutral-200"></div>
      </div>
      <div class="px-8 py-6">
        <h2 class="mb-5 text-2xl">Información del test</h2>
        <div
          class="grid grid-cols-1 gap-x-5 gap-y-3 md:grid-flow-col md:grid-cols-3"
        >
          <app-input
            [fieldName]="'Nombre del test'"
            [(value)]="selectedTest.name"
          ></app-input>
          <app-simple-dropdown
            class="self-end"
            [options]="testModeOptions"
            [(selectedValue)]="selectedTest.mode"
          >
          </app-simple-dropdown>
          <app-simple-dropdown
            *ngIf="selectedTest.mode === 'table'"
            class="self-end"
            [options]="tableModeOptions"
            [(selectedValue)]="selectedTest.tableMode"
          ></app-simple-dropdown>
        </div>
        <app-input
          [type]="'textarea'"
          class="mt-2 block"
          [fieldName]="'Descripción'"
          [(value)]="selectedTest.description"
        ></app-input>
        <ng-container
          *ngIf="
            selectedTest.mode === 'table' && selectedTest.tableMode === 'xy'
          "
        >
          <div class="mb-5"></div>
          <app-input
            [fieldName]="'Título de la primera columna'"
            [(value)]="selectedTest.tableFirstColTitle"
          >
          </app-input>
        </ng-container>

        <ng-container
          *ngIf="
            selectedTest.mode === 'table' && selectedTest.tableMode === 'xy'
          "
        >
          <div class="mb-10"></div>
          <div class="mb-4 flex items-center justify-between">
            <h1 class="text-2xl">Filas</h1>
            <app-button
              (clickEvent)="addRow()"
              [text]="'Añadir '"
              [icon]="'fas fa-plus'"
            ></app-button>
          </div>
          <div
            cdkDropList
            cdkDropListLockAxis="y"
            [cdkDropListDisabled]="protocolInUse ? true : null"
            class="test-list"
            (cdkDropListDropped)="dropRow($event)"
          >
            <div
              class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
              [class.cursor-default]="protocolInUse ? true : null"
              *ngFor="let field of selectedTest.tableRowFields; let i = index"
              cdkDrag
            >
              <div class="flex w-full items-center justify-between">
                <div class="transition-all duration-200">
                  <div
                    class="grid grid-cols-1 items-center gap-x-5 gap-y-3 md:grid-cols-3"
                  >
                    <app-input
                      (click)="stopEvent($event)"
                      (mousedown)="stopEvent($event)"
                      (mouseup)="stopEvent($event)"
                      [fieldName]="''"
                      [(value)]="field.title"
                    ></app-input>
                  </div>
                  <div *ngIf="field.type === 'select'" class="w-full pt-5">
                    <app-input
                      (click)="stopEvent($event)"
                      (mousedown)="stopEvent($event)"
                      (mouseup)="stopEvent($event)"
                      class="animated fadeIn"
                      [fieldName]="''"
                      [placeholder]="
                        'Opciones separadas por comas: Opción 1, Opción 2, Opción 3'
                      "
                      [(value)]="field.optionsString"
                    ></app-input>
                  </div>
                </div>
                <div
                  *ngIf="!protocolInUse"
                  (click)="deleteRow(i, $event)"
                  class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
                >
                  <i class="far fa-trash-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="mb-10"></div>
        <div class="mb-4 flex items-center justify-between">
          <h1 class="text-2xl">
            {{ selectedTest.mode !== 'table' ? 'Campos' : 'Columnas' }}
          </h1>
          <app-button
            (clickEvent)="addField()"
            [text]="'Añadir '"
            [icon]="'fas fa-plus'"
          ></app-button>
        </div>
        <div
          cdkDropList
          cdkDropListLockAxis="y"
          class="test-list"
          (cdkDropListDropped)="dropField($event)"
        >
          <div
            class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
            *ngFor="let field of selectedTest.fields; let i = index"
            cdkDrag
          >
            <div class="flex w-full items-center justify-between">
              <div class="transition-all duration-200">
                <div
                  class="grid grid-cols-1 items-center gap-x-5 gap-y-3 md:grid-cols-3"
                >
                  <app-input
                    (click)="stopEvent($event)"
                    (mousedown)="stopEvent($event)"
                    (mouseup)="stopEvent($event)"
                    [fieldName]="''"
                    [(value)]="field.title"
                  ></app-input>
                  <app-simple-dropdown
                    (click)="stopEvent($event)"
                    (mousedown)="stopEvent($event)"
                    (mouseup)="stopEvent($event)"
                    [options]="fieldTypeOptions"
                    [(selectedValue)]="field.type"
                  >
                  </app-simple-dropdown>
                  <app-simple-dropdown
                    (click)="stopEvent($event)"
                    (mousedown)="stopEvent($event)"
                    (mouseup)="stopEvent($event)"
                    *ngIf="selectedTest.mode !== 'table'"
                    [options]="fieldLeftRightOptions"
                    [(selectedValue)]="field.leftRight"
                  ></app-simple-dropdown>
                </div>
                <div *ngIf="field.type === 'select'" class="w-full pt-5">
                  <app-input
                    (click)="stopEvent($event)"
                    (mousedown)="stopEvent($event)"
                    (mouseup)="stopEvent($event)"
                    class="animated fadeIn"
                    [fieldName]="''"
                    [placeholder]="
                      'Opciones separadas por comas: Opción 1, Opción 2, Opción 3'
                    "
                    [(value)]="field.optionsString"
                  ></app-input>
                </div>
              </div>
              <div
                (click)="deleteField(i, $event)"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
              >
                <i class="far fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-10"></div>
        <div class="mb-4 flex items-center justify-between">
          <h1 class="text-2xl">Imágenes</h1>
          <app-button
            (clickEvent)="addImage()"
            [text]="'Añadir '"
            [icon]="'fas fa-plus'"
          ></app-button>
        </div>
        <div
          cdkDropList
          cdkDropListLockAxis="y"
          class="test-list"
          (cdkDropListDropped)="dropImage($event)"
        >
          <div
            class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
            *ngFor="let image of selectedTest.images; let i = index"
            cdkDrag
          >
            <div class="flex w-full items-center justify-between">
              <div class="transition-all duration-200">
                <div
                  class="grid grid-cols-1 items-center gap-x-5 gap-y-3 md:grid-cols-2"
                >
                  <app-input
                    [fieldName]="'Nombre'"
                    [(value)]="image.name"
                  ></app-input>
                  <app-simple-dropdown
                    [fieldName]="'Ubicación en el informe PDF'"
                    [options]="imagePositionOptions"
                    [(selectedValue)]="image.position"
                  ></app-simple-dropdown>
                </div>
                <div *ngIf="image.type === 'option'" class="w-full pt-5">
                  <app-input
                    class="animated fadeIn"
                    [fieldName]="''"
                    [placeholder]="
                      'Opciones separadas por comas: Opción 1, Opción 2, Opción 3'
                    "
                    [(value)]="image.optionsString"
                  ></app-input>
                </div>
              </div>
              <div
                (click)="deleteImage(i, $event)"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
              >
                <i class="far fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-10"></div>
        <div class="mb-4 flex items-center justify-between">
          <div class="">
            <h1 class="text-2xl">Imágenes fijas (Opcional)</h1>
            <!-- <p>Si quieres puedes añadir imágenes fijas que saldrán siempre en todos los informes</p> -->
          </div>
          <app-button
            (clickEvent)="addFixedImage()"
            [text]="'Añadir '"
            [icon]="'fas fa-plus'"
          ></app-button>
        </div>
        <div
          cdkDropList
          cdkDropListLockAxis="y"
          class="test-list"
          (cdkDropListDropped)="dropFixedImage($event)"
        >
          <div
            class="test-box mb-3 cursor-pointer rounded-md border border-neutral-300 bg-white px-5 py-5 focus:shadow-xl"
            *ngFor="let image of selectedTest.fixedImages; let i = index"
            cdkDrag
          >
            <div class="flex w-full items-center justify-between">
              <div class="transition-all duration-200">
                <app-input
                  class="mb-2 block"
                  [fieldName]="'Título'"
                  [(value)]="image.title"
                ></app-input>
                <div
                  class="grid w-full grid-cols-1 items-end gap-x-5 gap-y-3 md:grid-cols-2"
                >
                  <app-input
                    [readonly]="true"
                    [fieldName]="'URL de la imagen'"
                    [(value)]="image.downloadUrl"
                    [button]="getImageUrlCopyButton(image.downloadUrl)"
                  >
                  </app-input>
                  <app-button
                    (clickEvent)="uploadFixedImage(i)"
                    [text]="'Subir imagen '"
                    [icon]="'fas fas fa-upload'"
                  >
                  </app-button>
                </div>
                <div
                  class="grid grid-cols-1 items-end gap-x-5 gap-y-3 pt-5 md:grid-cols-2"
                >
                  <app-input
                    [placeholder]="'https://...'"
                    [fieldName]="'Enlace (opcional)'"
                    [(value)]="image.link"
                  >
                  </app-input>
                  <app-simple-dropdown
                    [fieldName]="'Ubicación en el informe PDF'"
                    [options]="imagePositionOptions"
                    [(selectedValue)]="image.position"
                  ></app-simple-dropdown>
                </div>
              </div>
              <div
                (click)="deleteFixedImage(i, $event)"
                class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-200/0 transition-all duration-200 hover:bg-blue-200/100"
              >
                <i class="far fa-trash-alt"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
