export const EXERCISESSECTIONS_PATH = 'exercises-sections';

export interface ExercisesSectionItem {
  name: string;
  exerciseIds: Array<string>;
}
export interface ExercisesSection {
  id?: string;
  owner: string;
  sections: Array<ExercisesSectionItem>;
}

export interface GlobalExercisesSection {
  id?: string;
  sections: Array<ExercisesSectionItem>;
  isGlobal: true;
}
