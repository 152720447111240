import { Injectable } from '@angular/core';
import { SubscriptionsService } from './subscriptions.service';
import { NotificationsService } from './notifications.service';
import { first } from 'rxjs/operators';
import { UserConfigServiceService } from './user-config-service.service';
import {
  hasPermission,
  ActionIds,
  type ActionId,
  productHasPermission,
} from 'src/shared/subscriptions.shared';
import { AppConfig, AppName } from 'src/config/app.config';
import { firstValueFrom } from 'rxjs';
import { safeToPromise } from '../utils/utils';

const TRIAL_DURATION_DAYS = 7;

@Injectable({
  providedIn: 'root',
})
export class CanIService {
  constructor(
    private subscriptionService: SubscriptionsService,
    private notService: NotificationsService,
    private userConfigService: UserConfigServiceService,
  ) {}

  private async canCreateNewClient(clientCount: number) {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.CreateClient,
      clientCount,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }

    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );

    if (!activePrices) {
      return true;
    }

    const result = this.hasPermissionOrTrial(
      activePrices,
      ActionIds.CreateClient,
      clientCount,
    );

    return result;
  }

  public async canViewTests(app: AppName): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.VAFViewTests,
    ); // for new subscriptions, only vaf
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }

    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    if (app === 'rom') {
      const result = this.hasPermissionOrTrial(
        activePrices,
        ActionIds.RomViewTests,
      );
      // console.log('result', result);
      return result;
    } else if (app === 'raquis') {
      const result = this.hasPermissionOrTrial(
        activePrices,
        ActionIds.RaquisViewTests,
      );
      return result;
    } else if (app === 'vaf') {
      const result = this.hasPermissionOrTrial(
        activePrices,
        ActionIds.VAFViewTests,
      );
      return result;
    }
    return false;
  }

  public async canUseMyVaf(app: AppName): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.VAFUseMyVAF,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }
    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    const result = this.hasPermissionOrTrial(
      activePrices,
      ActionIds.VAFUseMyVAF,
    );
    return result;
  }

  public async canViewTrainingSessions(): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.InteccUseTrainingSessions,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }
    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    const result = this.hasPermissionOrTrial(
      activePrices,
      ActionIds.InteccUseTrainingSessions,
    );
    return result;
  }

  public async canViewCorrectiveSessions(): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.InteccUseCorrectiveSessions,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }
    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    const result = this.hasPermissionOrTrial(
      activePrices,
      ActionIds.InteccUseCorrectiveSessions,
    );
    return result;
  }

  public async canUseTrainingAvatar(): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.InteccUseTrainingAvatar,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }

    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    const result = this.hasPermissionOrTrial(
      activePrices,
      ActionIds.InteccUseTrainingAvatar,
    );
    return result;
  }

  async requestUseTrainingAvatar(): Promise<boolean> {
    const can = await this.canUseTrainingAvatar();
    if (!can) {
      this.notService.component.showUpgradeNotification({
        title: 'No puedes usar el avatar de entrenamiento con tu plan actual.',
        description:
          'Pulsa el botón "Mejorar" para desbloquear esta funcionalidad.',
      });
    }
    return can;
  }

  async canCustomizeReports(): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.VAFExportPdfCustom,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }
    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );

    let action: ActionId;

    if (AppConfig.app === 'raquis') {
      action = ActionIds.RaquisExportPdfCustom;
    } else if (AppConfig.app === 'rom') {
      action = ActionIds.RomExportPdfCustom;
    } else {
      action = ActionIds.VAFExportPdfCustom;
    }

    const result = this.hasPermissionOrTrial(activePrices, action);

    return result;
  }

  async requestCreateNewClient(clientCount: number): Promise<boolean> {
    const can = await this.canCreateNewClient(clientCount);
    if (!can) {
      this.notService.component.showUpgradeNotification({
        title:
          'Has alcanzado el número máximo de clientes para tu plan actual.',
        description:
          'Pulsa el botón "Mejorar" para sacar el máximo partido a Raquis y disfrutar de todas sus funcionalidades.',
      });
    }
    return can;
  }

  private async canGenerateReport(): Promise<boolean> {
    const currentCount =
      await this.userConfigService.getTotalReportsGeneratedCounter();
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.VAFExportPdf,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }

    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    let action: ActionId;
    if (AppConfig.app === 'raquis') {
      action = ActionIds.RaquisExportPdf;
    } else if (AppConfig.app === 'rom') {
      action = ActionIds.RomExportPdf;
    } else {
      action = ActionIds.VAFExportPdf;
    }
    const result = this.hasPermissionOrTrial(
      activePrices,
      action,
      currentCount,
    );
    return result;
  }

  async requestGenerateReport(): Promise<boolean> {
    const can = await this.canGenerateReport();
    if (!can) {
      this.notService.component.showUpgradeNotification({
        title: '¡Ya has generado tus 5 informes de prueba!',
        description: 'Actualiza a Raquis Pro para generar informes ilimitados.',
      });
    } else {
      await this.userConfigService.countUpTotalReportsGenerated();
    }
    return can;
  }

  private async canCreateTest(
    currentCount: number,
    raquis = false,
  ): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.VAFCreateTest,
      currentCount,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }
    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    let action: ActionId;
    if (AppConfig.app === 'raquis') {
      action = ActionIds.RaquisCreateTest;
    } else if (AppConfig.app === 'rom') {
      action = ActionIds.RomCreateTest;
    } else {
      if (raquis) {
        action = ActionIds.VAFCreateRaquis;
      } else {
        action = ActionIds.VAFCreateTest;
      }
    }
    const result = this.hasPermissionOrTrial(
      activePrices,
      action,
      currentCount,
    );
    return result;
  }

  async requestCreateTest(
    currentCount: number,
    raquis = false,
  ): Promise<boolean> {
    const can = await this.canCreateTest(currentCount, raquis);
    if (!can) {
      this.notService.component.showUpgradeNotification({
        title: 'Has creado el número máximo de valoraciones para este cliente.',
        description:
          'Actualiza para realizar tantas valoraciones como quieras.',
      });
    }
    return can;
  }

  private async canExportCsv(): Promise<boolean> {
    const subscriptionPermission = await this.getSubscriptionPermission(
      ActionIds.RomExportCsv,
    );
    if (subscriptionPermission) {
      return subscriptionPermission.hasPermission;
    }
    const activePrices = await safeToPromise(
      this.subscriptionService.currentUserActivePrices$.pipe(first()),
    );
    let action: ActionId;
    if (AppConfig.app === 'raquis') {
      action = ActionIds.RaquisExportCsv;
    } else {
      action = ActionIds.RomExportCsv;
    }
    const result = this.hasPermissionOrTrial(activePrices, action);
    return result;
  }

  async requestExportCsv(): Promise<boolean> {
    const can = await this.canExportCsv();
    if (!can) {
      this.notService.component.showUpgradeNotification({
        title:
          'Mejora a Raquis Pro para poder exportar todas tus valoraciones a CSV.',
        description:
          'Pulsa el botón para mejorar y sacar el máximo rendimiento de Raquis',
      });
    }
    return can;
  }

  async hasPermissionOrTrial(
    activePrices: string[],
    actionId: ActionId,
    limit?: number,
  ) {
    const activeTrial = await this.subscriptionService.hasTrial();
    if (activeTrial) {
      console.log('Active trial');
      return true;
    }
    return hasPermission(activePrices, actionId, limit);
  }

  async getSubscriptionPermission(
    actionId: ActionId,
    count?: number,
  ): Promise<{ hasPermission: boolean }> | null {
    const currentUserPortalAndSubscription = await firstValueFrom(
      this.subscriptionService.currentUserSubscription$.pipe(first()),
    );
    const { subscription } = currentUserPortalAndSubscription ?? {};
    if (!subscription) {
      return null; // this will make the flow go through the old subscription system
    }

    const hasPermission = productHasPermission(
      subscription.productId,
      actionId,
      count,
    );

    return { hasPermission };
  }
}
