<div class="email-wall" *ngIf="user$ | async as user">
  <div class="email-wall__intecc-logo">
    <img
      src="../../../assets/images/intecc-with-bg-rounded.png"
      appResponsiveSrc
      alt=""
    />
  </div>
  <h2 class="email-wall__title">¡Bienvenid&#64;!</h2>
  <div class="email-wall__not-sent">
    <p *ngIf="user.role === 'client'" class="email-wall__p">
      Estás a un paso de mejorar tu salud y estado físico 🚀
    </p>
    <p *ngIf="user.role === 'professional'" class="email-wall__p">
      Estás a un paso de mejorar como PROfesional 🚀
    </p>

    <form
      *ngIf="user.role !== 'client'"
      [formGroup]="userForm"
      class="mx-auto my-5 w-full max-w-md text-left"
    >
      <div class="mb-4">
        <label
          for="nombreYApellidos"
          class="mb-1 block text-left text-sm font-medium text-gray-700"
          >Nombre y Apellidos</label
        >
        <input
          type="text"
          id="nombreYApellidos"
          formControlName="nombreYApellidos"
          placeholder="Introduce tu nombre y apellidos"
          class="focus:ring-primary focus:border-primary w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none"
        />
      </div>

      <div class="mb-4">
        <label
          for="email"
          class="mb-1 block text-left text-sm font-medium text-gray-700"
          >Correo</label
        >
        <input
          type="email"
          id="email"
          [value]="user.email"
          disabled
          class="w-full cursor-not-allowed rounded-md border border-gray-300 bg-gray-100 px-3 py-2"
        />
      </div>

      <div class="mb-4">
        <label
          for="telefono"
          class="mb-1 block text-left text-sm font-medium text-gray-700"
          >Teléfono</label
        >
        <input
          type="tel"
          id="telefono"
          formControlName="telefono"
          placeholder="Introduce tu teléfono"
          class="focus:ring-primary focus:border-primary w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none"
        />
      </div>

      <div class="mb-4">
        <label
          for="poblacion"
          class="mb-1 block text-left text-sm font-medium text-gray-700"
          >Población</label
        >
        <input
          type="text"
          id="poblacion"
          formControlName="poblacion"
          placeholder="Introduce tu población"
          class="focus:ring-primary focus:border-primary w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:outline-none"
        />
      </div>
    </form>

    <div class="email-wall__marketing-checkbox">
      <input
        type="checkbox"
        [(ngModel)]="marketing"
        name="marketing"
        id="marketing"
        class="mr-2"
      />
      <label for="marketing" class="text-sm">
        Acepto la
        <a
          class="text-primary hover:text-primary-dark underline"
          target="_blank"
          href="https://www.intecc.org/blog/rgdp/politica-de-privacidad"
        >
          política de privacidad
        </a>
        y
        <a
          class="text-primary hover:text-primary-dark underline"
          target="_blank"
          href="https://www.intecc.org/blog/rgdp/terminos-y-condiciones-generales-de-venta"
        >
          términos y condiciones generales
        </a>
      </label>
    </div>
    <button
      [disabled]="
        sending || !marketing || (user.role !== 'client' && userForm.invalid)
      "
      (click)="acceptConditions()"
      [class.button-solid--disabled]="
        sending || !marketing || (user.role !== 'client' && userForm.invalid)
      "
      class="button-solid mt-4 disabled:cursor-not-allowed"
    >
      {{ sending ? 'Un momento...' : 'Accede a la App' }}
    </button>
  </div>

  <div class="email-wall__video">
    <!-- <img src="../../../assets/vault.gif" alt=""> -->
    <video width="100%" autoplay loop>
      <source src="../../../assets/vault.mp4" type="video/mp4" />
      Your browser does not support the video element.
    </video>
  </div>

  <a href="#" (click)="auth.signOut()" class="email-wall__link"
    >Cerrar sesión</a
  >
</div>
