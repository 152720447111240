<div class="content" *ngIf="loadingState === 'idle'">
  <div class="flex h-full flex-col items-center">
    <div class="relative mb-4 h-44 w-44">
      <img
        class="h-full w-full object-contain"
        [src]="
          userConfig?.reportsConfig?.logoUrl
            ? userConfig.reportsConfig.logoUrl
            : '../../../assets/images/intecc-with-bg-rounded.png'
        "
        alt=""
      />
    </div>
    <div
      class="mb-8 w-full max-w-lg space-y-4 rounded-2xl bg-white px-4 py-6 shadow-md shadow-neutral-200"
      *ngIf="shouldDisplayFirstBox"
    >
      <div class="space-y-2 text-center text-lg font-semibold">
        <div *ngIf="userConfig.reportsConfig.displayLine1">
          {{ userConfig.reportsConfig.line1 }}
        </div>
        <div *ngIf="userConfig.reportsConfig.displayLine2">
          {{ userConfig.reportsConfig.line2 }}
        </div>
        <div *ngIf="userConfig.reportsConfig.displayLine3">
          {{ userConfig.reportsConfig.line3 }}
        </div>
      </div>
    </div>
    <div
      class="w-full max-w-lg space-y-2 rounded-2xl bg-white px-4 py-6 shadow-md shadow-neutral-200"
    >
      <div
        *ngIf="connectLinks.length"
        class="mb-8 flex flex-wrap justify-center space-x-3 space-y-1"
      >
        @for (connectLink of connectLinks; track connectLink) {
          <a
            [href]="
              connectLink.key === 'connectEmail' &&
              !connectLink.url.startsWith('mailto:')
                ? 'mailto:' + connectLink.url
                : connectLink.url
            "
            target="_blank"
            class="flex shrink-0 items-center justify-center p-2 text-3xl text-neutral-600 visited:text-neutral-600"
          >
            <i class="{{ connectLink.icon }}"></i>
          </a>
        }
      </div>
      <button
        (click)="openAccountModal()"
        class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
      >
        <div class="grid grid-cols-[40px_100px] place-items-center">
          <div class="mr-2"><i class="fas fa-user-circle"></i></div>
          <div class="place-self-start">Mi cuenta</div>
        </div>
      </button>
      <a
        [routerLink]="['/my-profile']"
        class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
      >
        <div class="grid grid-cols-[40px_100px] place-items-center">
          <div class="mr-2"><i class="fas fa-user"></i></div>
          <div class="place-self-start">Mis datos</div>
        </div>
      </a>
      <a
        [routerLink]="['/clients']"
        class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
      >
        <div class="grid grid-cols-[40px_100px] place-items-center">
          <div class="mr-2"><i class="fas fa-user-friends"></i></div>
          <div class="place-self-start">Mis clientes</div>
        </div>
      </a>
      <button
        *ngIf="canUseExercises"
        (click)="openExercises()"
        class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
      >
        <div class="grid grid-cols-[40px_100px] place-items-center">
          <div class="mr-2"><i class="fas fa-dumbbell"></i></div>
          <div class="place-self-start">Ejercicios</div>
        </div>
      </button>
      <button
        (click)="openMultimedia()"
        class="flex w-full justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
      >
        <div class="grid grid-cols-[40px_100px] place-items-center">
          <div class="mr-2"><i class="fas fa-camera"></i></div>
          <div class="place-self-start">Multimedia</div>
        </div>
      </button>
      <a
        href="https://manual.intecc.org/es/article/presentacion-del-manual"
        target="_blank"
        class="flex w-full items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
      >
        <div class="grid grid-cols-[40px_100px] place-items-center">
          <div class="mr-2"><i class="fas fa-book"></i></div>
          <div class="place-self-start">Academia</div>
        </div>
      </a>
      <!-- <a
        href="https://intecc.ebforms.com/6497099602722816"
        target="_blank"
        class="flex w-full items-center justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-black"
      >
        <div class="grid grid-cols-[40px_100px] place-items-center">
          <div class="mr-2"><i class="fas fa-graduation-cap"></i></div>
          <div class="place-self-start">Formulario</div>
        </div>
      </a> -->
    </div>
    <div class="h-16 shrink-0 text-transparent">_</div>
  </div>
</div>
