import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './pages/login/login.component';
import { ResponsiveSrcDirective } from './directives/responsive-src.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import {
  // getApp,
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';

import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import {
//   provideFirestore,
//   getFirestore,
//   initializeFirestore,
// } from '@angular/fire/firestore';

import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';

import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './pages/header/header.component';
import { AddClientComponent } from './pages/add-client/add-client.component';

import * as Sentry from '@sentry/browser';
import { SentryService } from './services/sentry.service';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientComponent } from './pages/client/client.component';
import { TestComponent } from './pages/test/test.component';
import { ClientBackButtonComponent } from './elements/client-back-button/client-back-button.component';
import { SagitalTestComponent } from './components/tests/sagital-test/sagital-test.component';
import { InclinometerComponent } from './elements/inclinometer/inclinometer.component';
import { TestResultTagComponent } from './elements/test-result-tag/test-result-tag.component';
import { TestAngleValueComponent } from './elements/test-angle-value/test-angle-value.component';
import { ConfirmActionDialogComponent } from './elements/confirm-action-dialog/confirm-action-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TwoStateButtonComponent } from './elements/two-state-button/two-state-button.component';
import { LearnComponent } from './components/learn/learn.component';
import { LearnSectionHostDirective } from './directives/learn-section-host.directive';
import { SagitalInclinometerLearnContainerComponent } from './components/learn-containers/sagital-inclinometer-learn-container/sagital-inclinometer-learn-container.component';
import { NormalValueViewerComponent } from './elements/normal-value-viewer/normal-value-viewer.component';
import { FloatingVideoComponent } from './components/floating-video/floating-video.component';
import { SagitalImgTestComponent } from './components/tests/sagital-img-test/sagital-img-test.component';
import { SagitalImageValorationSelectorComponent } from './components/sagital-image-valoration-selector/sagital-image-valoration-selector.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalHostDirective } from './directives/modal-host.directive';
import { CreateEditClientModalComponent } from './components/create-edit-client-modal/create-edit-client-modal.component';
import { SagitalFlechasComponent } from './components/tests/sagital-flechas/sagital-flechas.component';
import { ActionInProgressComponent } from './components/action-in-progress/action-in-progress.component';
import { TagSelectorComponent } from './elements/tag-selector/tag-selector.component';
import { ExportTestsModalComponent } from './components/export-tests-modal/export-tests-modal.component';
import { DropdownComponent } from './elements/dropdown/dropdown.component';
import { GifModalComponent } from './components/gif-modal/gif-modal.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { AccountModalComponent } from './components/account-modal/account-modal.component';
import { CancelSubModalComponent } from './components/cancel-sub-modal/cancel-sub-modal.component';
import { RomTestComponent } from './components/tests/rom-test/rom-test.component';
import { StyleComponent } from './pages/style/style.component';
import { RomInclinometerComponent } from './components/rom-inclinometer/rom-inclinometer.component';
import { ResultBarComponent } from './components/result-bar/result-bar.component';
import { LiveInclinometerViewerComponent } from './components/live-inclinometer-viewer/live-inclinometer-viewer.component';
import { RomResultTagComponent } from './elements/rom-result-tag/rom-result-tag.component';
import { InclinometerReaderComponent } from './elements/inclinometer-reader/inclinometer-reader.component';
import { NewUpgradeModalComponent } from './modals/new-upgrade-modal/new-upgrade-modal.component';
import { RomTestCardComponent } from './components/rom-test-card/rom-test-card.component';
import { LeftRightToggleComponent } from './component/left-right-toggle/left-right-toggle.component';
import { FilterSearchComponent } from './components/filter-search/filter-search.component';
import { FilterSearchModalComponent } from './modals/filter-search-modal/filter-search-modal.component';
import { EmailWallComponent } from './pages/email-wall/email-wall.component';
import { SideModalComponent } from './components/side-modal/side-modal.component';
import { ClientMediaComponent } from './side-modals/client-media/client-media.component';
import { DropzoneDirective } from './directives/dropzone.directive';
import { ButtonComponent } from './elements/button/button.component';
import { NewValorationMenuComponent } from './components/new-valoration-menu/new-valoration-menu.component';
import { TabItemComponent } from './elements/tab-item/tab-item.component';
import { BottomModalComponent } from './components/bottom-modal/bottom-modal.component';
import { MediaEditComponent } from './bottom-modals/media-edit/media-edit.component';
import { EditedImageViewComponent } from './components/edited-image-view/edited-image-view.component';
import { PostureComponent } from './components/tests/posture/posture.component';
import { TestImageViewComponent } from './components/test-image-view/test-image-view.component';
import { TestFieldDropdownComponent } from './elements/test-field-dropdown/test-field-dropdown.component';
import { RenderEditedImageComponent } from './pages/render-edited-image/render-edited-image.component';
import { TestReportLinksComponent } from './components/test-report-links/test-report-links.component';
import { MediaEditInputModalComponent } from './components/media-edit-input-modal/media-edit-input-modal.component';
import { FootComponent } from './components/tests/foot/foot.component';
import { IconToggleComponent } from './elements/icon-toggle/icon-toggle.component';
import { VafLearnContainerComponent } from './components/learn-containers/vaf-learn-container/vaf-learn-container.component';
import { RomShortComponent } from './components/tests/rom-short/rom-short.component';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { TestHeaderComponent } from './components/test-header/test-header.component';
import { MyVafMenuComponent } from './components/my-vaf-menu/my-vaf-menu.component';
import { MyvafProtocolEditComponent } from './pages/myvaf-protocol-edit/myvaf-protocol-edit.component';
import { TrainingSessionsMenuComponent } from './components/training-sessions-menu/training-sessions-menu.component';
import { CorrectiveSessionsMenuComponent } from './components/corrective-sessions-menu/corrective-sessions-menu.component';
import { InputComponent } from './elements/input/input.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { SimpleDropdownComponent } from './elements/simple-dropdown/simple-dropdown.component';
import { MyvafProtocolComponent } from './components/tests/myvaf-protocol/myvaf-protocol.component';
import { DragScrollDirective } from './directives/drag-scroll.directive';
import { AcademyMenuComponent } from './components/academy-menu/academy-menu.component';
import { AccordionComponent } from './elements/accordion/accordion.component';
import { DuplicateMyVafProtocolComponent } from './pages/duplicate-my-vaf/duplicate-my-vaf.component';
import { SideModalLeftComponent } from './components/side-modal-left/side-modal-left.component';
import { AvatarComponent } from './side-modals/avatar/avatar.component';
import { AvatarFieldStatusComponent } from './elements/avatar-field-status/avatar-field-status.component';
import { AvatarStatusItemComponent } from './elements/avatar-status-item/avatar-status-item.component';
import { TwoTabButtonComponent } from './elements/two-tab-button/two-tab-button.component';
import { AvatarStatusIndicatorComponent } from './elements/avatar-status-indicator/avatar-status-indicator.component';
import { UserComponent } from './user/user.component';
import { CheckboxComponent } from './elements/checkbox/checkbox.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ItemsListComponent } from './items-list/items-list.component';
import { TrainingSessionProtocolEditComponent } from './pages/training-session-protocol-edit/training-session-protocol-edit.component';
import { CorrectiveSessionProtocolEditComponent } from './pages/corrective-session-protocol-edit/corrective-session-protocol-edit.component';
import { DuplicateTrainingSessionProtocolComponent } from './pages/duplicate-training-session/duplicate-training-session.component';
import { DuplicateCorrectiveSessionProtocolComponent } from './pages/duplicate-corrective-session/duplicate-corrective-session.component';
import { ClientTrainingSessionComponent } from './pages/client-training-session/client-training-session.component';
import { ClientCorrectiveSessionComponent } from './pages/client-corrective-session/client-corrective-session.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NewDropdownComponent } from './new-dropdown/new-dropdown.component';
import { ClientSegmentsLevelSectionComponent } from './client-segments-level-section/client-segments-level-section.component';
import { TrainingAvatarComponent } from './components/training-avatar/training-avatar.component';
import { AcceptConditionsComponent } from './accept-conditions/accept-conditions.component';
import { ClientLoginComponent } from './pages/client-login/client-login.component';
import { ClientHomeComponent } from './pages/client-home/client-home.component';
import { JoinProfessionalComponent } from './pages/join-professional/join-professional.component';
import { ProfessionalLoginComponent } from './pages/professional-login/professional-login.component';
import { ClientTestsComponent } from './pages/client-tests/client-tests.component';
import { ClientTrainingComponent } from './pages/client-training/client-training.component';
import {
  ClientProfessionalComponent,
  UnlinkConfirmationDialogComponent,
} from './pages/client-professional/client-professional.component';
import { LinkClientModalComponent } from './components/link-client-modal/link-client-modal.component';
import { ErrorFeedbackComponent } from './components/error-feedback/error-feedback.component';
import { LoaderFeedbackComponent } from './components/loader-feedback/loader-feedback.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { NoEliteSubscriptionFeedbackComponent } from './components/no-elite-subscription-feedback/no-elite-subscription-feedback.component';
import { ClientClientComponent } from './pages/client-client/client-client.component';
import { ExercisesComponent } from './side-modals/exercises/exercises.component';

// import { MarkdownModule } from 'ngx-markdown';

Sentry.init({
  dsn: 'https://14e823cf66ba4449aed62532cadba787@sentry.io/1508999',
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private sentry: SentryService) {}
  handleError(error) {
    const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
    this.sentry.logError(error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResponsiveSrcDirective,
    HomeComponent,
    HeaderComponent,
    AddClientComponent,
    ClientsComponent,
    ClientComponent,
    TestComponent,
    ClientBackButtonComponent,
    SagitalTestComponent,
    InclinometerComponent,
    TestResultTagComponent,
    TestAngleValueComponent,
    ConfirmActionDialogComponent,
    TwoStateButtonComponent,
    LearnComponent,
    LearnSectionHostDirective,
    SagitalInclinometerLearnContainerComponent,
    NormalValueViewerComponent,
    FloatingVideoComponent,
    SagitalImgTestComponent,
    SagitalImageValorationSelectorComponent,
    ModalComponent,
    ModalHostDirective,
    CreateEditClientModalComponent,
    SagitalFlechasComponent,
    ActionInProgressComponent,
    TagSelectorComponent,
    ExportTestsModalComponent,
    DropdownComponent,
    GifModalComponent,
    SplashScreenComponent,
    NotificationsComponent,
    AccountModalComponent,
    CancelSubModalComponent,
    RomTestComponent,
    StyleComponent,
    RomInclinometerComponent,
    ResultBarComponent,
    LiveInclinometerViewerComponent,
    RomResultTagComponent,
    InclinometerReaderComponent,
    NewUpgradeModalComponent,
    RomTestCardComponent,
    LeftRightToggleComponent,
    FilterSearchComponent,
    FilterSearchModalComponent,
    EmailWallComponent,
    SideModalComponent,
    ClientMediaComponent,
    DropzoneDirective,
    ButtonComponent,
    NewValorationMenuComponent,
    TabItemComponent,
    BottomModalComponent,
    MediaEditComponent,
    EditedImageViewComponent,
    PostureComponent,
    TestImageViewComponent,
    TestFieldDropdownComponent,
    RenderEditedImageComponent,
    TestReportLinksComponent,
    MediaEditInputModalComponent,
    FootComponent,
    IconToggleComponent,
    VafLearnContainerComponent,
    RomShortComponent,
    MarkdownPipe,
    TestHeaderComponent,
    MyVafMenuComponent,
    MyvafProtocolEditComponent,
    TrainingSessionsMenuComponent,
    CorrectiveSessionsMenuComponent,
    TrainingSessionProtocolEditComponent,
    CorrectiveSessionProtocolEditComponent,
    InputComponent,
    SimpleDropdownComponent,
    MyvafProtocolComponent,
    DragScrollDirective,
    AcademyMenuComponent,
    AccordionComponent,
    DuplicateMyVafProtocolComponent,
    SideModalLeftComponent,
    AvatarComponent,
    AvatarFieldStatusComponent,
    AvatarStatusItemComponent,
    TwoTabButtonComponent,
    AvatarStatusIndicatorComponent,
    UserComponent,
    CheckboxComponent,
    BackButtonComponent,
    ItemsListComponent,
    DuplicateTrainingSessionProtocolComponent,
    DuplicateCorrectiveSessionProtocolComponent,
    ClientTrainingSessionComponent,
    ClientCorrectiveSessionComponent,
    NewDropdownComponent,
    ClientSegmentsLevelSectionComponent,
    TrainingAvatarComponent,
    AcceptConditionsComponent,
    ClientLoginComponent,
    ClientHomeComponent,
    JoinProfessionalComponent,
    ProfessionalLoginComponent,
    ClientTestsComponent,
    ClientTrainingComponent,
    ClientProfessionalComponent,
    LinkClientModalComponent,
    ErrorFeedbackComponent,
    LoaderFeedbackComponent,
    UnlinkConfirmationDialogComponent,
    NoEliteSubscriptionFeedbackComponent,
    ClientClientComponent,
    ExercisesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    // provideFirestore(() =>
    //   initializeFirestore(getApp(), { ignoreUndefinedProperties: true }),
    // ),
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatTableModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTooltipModule,
    DragDropModule,
    CdkAccordionModule,
    // MarkdownModule.forRoot()
    MatDialogModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFunctions(() => getFunctions()),
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    MatDatepickerModule,
    MatSlideToggleModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
