import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import {
  Exercise,
  TrainingExercise,
  CorrectiveExercise,
  correctiveSegmentOptions,
  materialOptions,
} from '../../models/exercise.model';
import {
  ExercisesSection,
  ExercisesSectionItem,
  GlobalExercisesSection,
} from '../../models/exercises-section.model';
import { ExerciseService } from '../../services/exercise.service';
import { AuthService } from '../../services/auth.service';
import { Modal } from '../../models/modal.model';
import { ConfirmActionDialogService } from '../../services/confirm-action-dialog.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ModalService } from 'src/app/services/modal.service';
import { CanIService } from 'src/app/services/can-i.service';
import { LoadingState } from 'src/app/utils/utils';
import {
  getLevelTextByNumber,
  levelDropdownOptions,
  levelsMapToNumber,
  segmentsMap,
} from '../../../../../Server/functions/src/shared/training-avatar.shared';

export interface ExercisesModalData {
  selectionMode?: boolean;
  selectionName?: string;
}

type SelectedItemsTab = 'personal' | 'library';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss'],
})
export class ExercisesComponent implements OnInit, OnDestroy, Modal {
  onClose = new Subject<any>();
  data: ExercisesModalData;

  noExercisesYet = true;
  tabState: 'items' | 'new' | 'edit' = 'items';
  selectedItemsTab: SelectedItemsTab = 'personal';

  sections: ExercisesSection;
  globalSections: GlobalExercisesSection | null = null;
  globalExercises$: Observable<Exercise[]>;
  userExercises$: Observable<Exercise[]>;
  userExercises: Exercise[] = [];
  globalExercises: Exercise[] = [];

  moveExerciseDialogOpen = false;
  selectedExerciseToMove: string | null = null;
  moveGlobalDialogOpen = false;

  selectedSection: ExercisesSection | null = null;
  selectedExercise: Exercise | null = null;
  newExercise: Exercise | null = null;
  editingExercise: Exercise | null = null;
  private subscriptions: Subscription[] = [];
  private userId: string | null = null;
  userIsAdmin = false;
  returnValue: any;

  editSectionNameSection: ExercisesSectionItem;
  editSectionNameOpen = false;
  editSectionNameText = '';

  multiSelectList: Exercise['id'][] = [];

  canUseExercises = false;

  loadingState: LoadingState = 'loading';

  // Options for dropdowns

  trainingActionOptions = Object.entries(segmentsMap).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  levelDropdownOptions = levelDropdownOptions.filter(
    (option) => !!option.value,
  );

  levelsMapToNumber = levelsMapToNumber;

  levelsMapToNumberEntries = Object.entries(levelsMapToNumber).filter(
    ([key]) => key !== 'Nivel sin determinar',
  );

  getLevelTextByNumber = getLevelTextByNumber;

  correctiveSegmentOptions = [
    { label: 'Seleccionar...', value: undefined },
    ...correctiveSegmentOptions,
  ];

  materialOptions = materialOptions;

  constructor(
    private exerciseService: ExerciseService,
    private authService: AuthService,
    private confirmActionDialogService: ConfirmActionDialogService,
    private snackbarService: SnackbarService,
    private firestoreService: FirestoreService,
    private mediaService: MediaService,
    private modals: ModalService,
    private canIService: CanIService,
  ) {}

  ngOnInit(): void {
    this.loadingState = 'loading';
    this.initAsync();
  }

  async initAsync() {
    const user = await this.authService.getUser();
    if (user) {
      // TODO: remove this 'admin' requirement once exercises new entity is finished
      this.canUseExercises =
        (await this.canIService.canViewTrainingSessions()) &&
        user.role === 'admin';
      this.userId = user.uid;
      this.userIsAdmin = user.role === 'admin';
      this.setupExerciseSubscriptions();
      this.sections = await this.exerciseService.getSectionsOrCreateSections(
        this.userId!,
      );
      this.globalSections = await this.exerciseService.getGlobalSections();
      this.loadingState = 'idle';
    }
  }

  setupExerciseSubscriptions() {
    // Setup user exercises subscription
    this.userExercises$ = this.exerciseService.getUserExercises(this.userId!);
    this.subscriptions.push(
      this.userExercises$.subscribe(
        (exercises) => {
          this.userExercises = exercises;
        },
        (error) => {
          console.error('Error loading user exercises:', error);
        },
      ),
    );

    // Setup global exercises subscription
    this.globalExercises$ = this.exerciseService.getGlobalExercises();
    this.subscriptions.push(
      this.globalExercises$.subscribe(
        (exercises) => {
          this.globalExercises = exercises;
        },
        (error) => {
          console.error('Error loading library exercises:', error);
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  historyBackClose() {
    this.onClose.next(this.returnValue);
  }

  handleTabChange(tab: SelectedItemsTab) {
    this.selectedItemsTab = tab;
  }

  /* SECTIONS */
  async addSection() {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    if (!sectionRef.sections) {
      sectionRef.sections = [];
    }
    sectionRef.sections.push({ name: 'Sección', exerciseIds: [] });
    this.updateSections();
  }

  getExerciseById(id: string) {
    return this.userExercises.find((e) => e.id === id);
  }

  getGlobalExerciseById(id: string) {
    return this.globalExercises.find((e) => e.id === id);
  }

  updateSections() {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    this.exerciseService.updateSections(sectionRef);
  }

  async deleteSection(index: number) {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    sectionRef.sections.splice(index, 1);
    this.updateSections();
  }

  async moveSectionUp(index: number, event: Event) {
    event.stopPropagation();
    if (index === 0) return;

    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    const hold = sectionRef.sections[index - 1];
    sectionRef.sections[index - 1] = sectionRef.sections[index];
    sectionRef.sections[index] = hold;
    this.updateSections();
  }

  async moveSectionDown(index: number, event: Event) {
    event.stopPropagation();

    // Only admins can modify global sections
    if (this.selectedItemsTab === 'library' && !this.userIsAdmin) {
      this.snackbarService.error(
        'No tienes permisos para modificar secciones globales',
      );
      return;
    }

    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    if (index === sectionRef.sections.length - 1) return;

    const hold = sectionRef.sections[index + 1];
    sectionRef.sections[index + 1] = sectionRef.sections[index];
    sectionRef.sections[index] = hold;
    this.updateSections();
  }

  openRenameSection(section: ExercisesSectionItem, event: Event) {
    event.stopPropagation();
    this.editSectionNameOpen = true;
    this.editSectionNameSection = section;
    this.editSectionNameText = section.name;
  }

  async endRenameSection() {
    this.editSectionNameSection.name = this.editSectionNameText;
    this.editSectionNameOpen = false;
    this.updateSections();
  }

  isValidExercise(exercise: Exercise | null): boolean {
    if (!exercise || !exercise.title.trim() || !exercise.id.trim()) {
      return false;
    }

    // For global exercises, only admins can create/edit
    if (this.selectedItemsTab === 'library' && !this.userIsAdmin) {
      return false;
    }

    // For personal exercises, must be owned by user
    if (
      this.selectedItemsTab === 'personal' &&
      exercise.owner !== this.userId
    ) {
      return false;
    }

    return true;
  }

  newExerciseMode() {
    this.tabState = 'new';
    this.newExercise = {
      id: this.firestoreService.createId(),
      owner: this.userId!,
      title: '',
      isGlobal: this.selectedItemsTab === 'library',
      type: 'training',
      action: undefined,
      level: [],
      trainingObjective: '',
      material: [] as string[],
    } as TrainingExercise;
  }

  selectExerciseById(exerciseId: string, isGlobal?: boolean) {
    const exercise = isGlobal
      ? this.getGlobalExerciseById(exerciseId)
      : this.getExerciseById(exerciseId);
    if (!exercise) {
      this.snackbarService.error('Ejercicio no encontrado');
      return;
    }
    if (this.data.selectionMode) {
      // TODO: implement multiple selection?
      this.returnValue = [exercise.id];
      this.historyBackClose();
      return;
    }
    if (exercise.isGlobal && !this.userIsAdmin) {
      console.warn('User is not admin, cannot edit global exercise');
      return;
    }
    this.editingExercise = exercise;
    this.tabState = 'edit';
  }

  async editExercise(exercise: Exercise, event: Event) {
    event.stopPropagation();
    this.editingExercise = { ...exercise };
    this.tabState = 'edit';
  }

  async createNewExercise() {
    try {
      if (!this.isValidExercise(this.newExercise))
        throw new Error('Invalid exercise');

      // Add isGlobal flag if creating in global section
      if (this.selectedItemsTab === 'library') {
        this.newExercise.isGlobal = true;
      }

      await this.exerciseService.createExercise(this.userId!, this.newExercise);
      this.tabState = 'items';
    } catch (error) {
      console.error('Error creating exercise:', error);
      this.snackbarService.error('Error creando ejercicio');
    }
  }

  async updateExercise() {
    try {
      if (!this.isValidExercise(this.editingExercise))
        throw new Error('Invalid exercise');
      await this.exerciseService.updateExercise(
        this.editingExercise!.id,
        this.editingExercise!,
      );
      this.tabState = 'items';
    } catch (error) {
      console.error('Error updating exercise:', error);
      this.snackbarService.error('Error actualizando ejercicio');
    }
  }

  selectGlobalExercise(exercise: Exercise) {
    if (this.data.selectionMode) {
      this.returnValue = { type: 'global', exercise };
      this.historyBackClose();
      return;
    }
    this.selectedExercise = exercise;
    this.tabState = 'edit';
  }

  openMoveExerciseDialog(exercise: Exercise | null, event: Event) {
    event.stopPropagation();
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;
    if (
      !sectionRef ||
      !sectionRef.sections ||
      sectionRef.sections.length === 0
    ) {
      this.snackbarService.error('No se encontraron secciones');
      return;
    }

    // Set selectedExerciseToMove even when no exercise is passed (multi-select case)
    if (exercise) {
      this.selectedExerciseToMove = exercise.id;
    } else if (this.multiSelectList.length > 0) {
      // No need to set selectedExerciseToMove for multi-select
      // as we'll use multiSelectList directly
    } else {
      return; // Exit if no exercise and no multi-selection
    }

    if (this.selectedItemsTab === 'personal') {
      this.moveExerciseDialogOpen = true;
    } else {
      this.moveGlobalDialogOpen = true;
    }
  }

  closeMoveToSection() {
    this.moveExerciseDialogOpen = false;
  }

  closeMoveToGlobal() {
    this.moveGlobalDialogOpen = false;
  }

  async moveToSection(section: ExercisesSectionItem) {
    const exercisesToMove =
      this.multiSelectList.length > 0
        ? this.multiSelectList
        : [this.selectedExerciseToMove];
    if (this.moveExerciseDialogOpen) {
      this.sections.sections.forEach((s) => {
        s.exerciseIds = s.exerciseIds.filter(
          (id) => !exercisesToMove.includes(id),
        );
      });
      this.moveExerciseDialogOpen = false;
    } else if (this.moveGlobalDialogOpen) {
      this.globalSections.sections.forEach((s) => {
        s.exerciseIds = s.exerciseIds.filter(
          (id) => !exercisesToMove.includes(id),
        );
      });
      this.moveGlobalDialogOpen = false;
    }
    section.exerciseIds.push(...exercisesToMove);
    this.multiSelectList = [];
    this.updateSections();
  }

  async deleteSelectedExercises() {
    if (!this.userId) return;

    const confirmed = await this.confirmActionDialogService.openDialog({
      title: `¿Eliminar ${this.multiSelectList.length} ejercicios?`,
      description: 'Esta acción no se puede deshacer',
      confirmButton: 'Eliminar',
      confirmCallback: async (confirm) => {
        if (confirm) {
          for (let i = 0; i < this.multiSelectList.length; i++) {
            const exerciseId = this.multiSelectList[i];
            await this.exerciseService.deleteExercise(exerciseId);
          }
          this.multiSelectList = [];
        }
      },
    });
  }

  getExercisesWithoutSection(exercises: Exercise[]): Exercise[] {
    const sectionRef =
      this.selectedItemsTab === 'personal'
        ? this.sections
        : this.globalSections;
    if (!sectionRef || !sectionRef.sections) return exercises;

    const result = exercises.filter(
      (e) =>
        !sectionRef.sections.find((section) =>
          section.exerciseIds.includes(e.id),
        ),
    );
    return result;
  }

  isExerciseWithoutSection(
    exercise: Exercise,
    exercisesWithoutSection: Exercise[],
  ): boolean {
    return !!exercisesWithoutSection.find((e) => e.id === exercise.id);
  }

  multiSelectItem(exercise: Exercise, event: Event) {
    event.stopPropagation();
    if (this.multiSelectList.includes(exercise.id)) {
      const index = this.multiSelectList.indexOf(exercise.id);
      this.multiSelectList.splice(index, 1);
    } else {
      this.multiSelectList.push(exercise.id);
    }
    console.log(this.multiSelectList);
  }

  isExerciseMultiSelected(exercise: Exercise): boolean {
    return this.multiSelectList.includes(exercise.id);
  }

  async selectImage(mode: 'new' | 'edit') {
    const result = await this.modals.openLateralModal(
      'client-media',
      {
        onlyOriginals: true,
        selectionMode: true,
        selectionName: 'Ejercicio',
      },
      {
        slideConfig: 'right',
        extraZIndex: 1000,
      },
    );

    if (!result) return;

    const mediaItem = await this.mediaService.getMediaItem(result.id);
    if (mode === 'new') {
      this.newExercise.imageUrl = mediaItem.downloadURL;
    } else {
      this.editingExercise.imageUrl = mediaItem.downloadURL;
    }
  }

  removeImage(mode: 'new' | 'edit') {
    if (mode === 'new') {
      this.newExercise.imageUrl = null;
    } else {
      this.editingExercise.imageUrl = null;
    }
  }

  // Helper methods for type casting
  asTrainingExercise(exercise: Exercise): TrainingExercise {
    return exercise as TrainingExercise;
  }

  asCorrectiveExercise(exercise: Exercise): CorrectiveExercise {
    return exercise as CorrectiveExercise;
  }

  isLevelSelected(level: number): boolean {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise || exercise.type !== 'training') return false;
    return (exercise as TrainingExercise).level?.includes(level) || false;
  }

  toggleLevel(level: number) {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise || exercise.type !== 'training') return;

    const trainingExercise = exercise as TrainingExercise;
    if (!trainingExercise.level) {
      trainingExercise.level = [];
    }

    const index = trainingExercise.level.indexOf(level);
    if (index === -1) {
      trainingExercise.level.push(level);
      trainingExercise.level.sort((a, b) => a - b);
    } else {
      trainingExercise.level.splice(index, 1);
    }
  }

  isMaterialSelected(material: Exercise['material'][number]): boolean {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise) return false;
    return (
      Array.isArray(exercise.material) && exercise.material.includes(material)
    );
  }

  toggleMaterial(material: Exercise['material'][number]) {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise) return;

    if (!Array.isArray(exercise.material)) {
      exercise.material = [];
    }

    const index = exercise.material.indexOf(material);
    if (index === -1) {
      exercise.material.push(material);
    } else {
      exercise.material.splice(index, 1);
    }
  }

  updateMaterials(materials: Exercise['material']) {
    const exercise =
      this.tabState === 'new' ? this.newExercise : this.editingExercise;
    if (!exercise) return;
    exercise.material = materials;
  }
}
