import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { NavigationService } from 'src/app/services/navigation.service';
import { FunctionsService } from '../services/functions.service';
import { safeToPromise } from '../utils/utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserConfig } from '../models/user-config.model';
import { UserConfigServiceService } from '../services/user-config-service.service';

interface UserFormData {
  nombreYApellidos: string;
  telefono: string;
  poblacion: string;
}

@Component({
  selector: 'app-accept-conditions',
  templateUrl: './accept-conditions.component.html',
  styleUrls: ['./accept-conditions.component.scss'],
})
export class AcceptConditionsComponent implements OnInit {
  emailSent = false;

  user$: Observable<User>;

  userConfig: UserConfig;

  sending = false;

  marketing = false;

  userForm: FormGroup;

  constructor(
    public auth: AuthService,
    public userConfigService: UserConfigServiceService,
    private nav: NavigationService,
    private functionsService: FunctionsService,
    private fb: FormBuilder,
  ) {
    this.userForm = this.fb.group({
      nombreYApellidos: ['', Validators.required],
      telefono: ['', Validators.required],
      poblacion: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.user$.pipe(take(1)).subscribe((user) => {
      if (user.conditionsAccepted === true) {
        this.roleBasedRedirect(user);
      }

      // If user is a client, remove form validation
      if (user.role === 'client') {
        this.userForm.disable();
      }
    });
  }

  roleBasedRedirect(user: User) {
    if (user.role === 'client') {
      this.nav.clientNav.goToHome();
    } else {
      this.nav.goToHome();
    }
  }

  loadData() {
    this.user$ = this.auth.user$;
    this.userConfigService.userConfig$.subscribe((config) => {
      this.userConfig = config;
    });
  }

  async acceptConditions() {
    this.sending = true;

    const user = await safeToPromise(this.auth.user$.pipe(take(1)));

    // Only validate form for non-client users
    if (user.role !== 'client' && this.userForm.invalid) {
      return;
    }

    // Only send form data and update config for non-client users
    if (user.role !== 'client') {
      const appStartDate = new Date().toISOString().split('T')[0];
      const response = await this.functionsService.call(
        'saveNotionContactCallable',
        {
          contact: {
            name: this.userForm.value.nombreYApellidos,
            email: user.email,
            phone: this.userForm.value.telefono,
            location: this.userForm.value.poblacion,
            date: new Date().toLocaleDateString('es-ES', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
            appStartDate,
          },
        },
      );

      const result = await this.functionsService.call(
        'sendVerificationEmailCallable',
        { email: user.email, name: user.displayName },
      );

      // console.log('acceptConditions result', result);

      this.userConfig.name = this.userForm.value.nombreYApellidos;
      this.userConfig.phone = this.userForm.value.telefono;
      this.userConfig.address = this.userForm.value.poblacion;
      this.userConfig.email = user.email;
      await this.userConfigService.updateUserConfigUid(
        user.uid,
        this.userConfig,
      );
    }

    user.conditionsAccepted = true;

    await this.auth.updateUser(user);

    this.sending = false;
    this.roleBasedRedirect(user);
  }
}
