import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent {
  @Input() name = 'Tab name';
  @Input() icon: string;
  @Input() selected = false;
  @Output() tapEvent = new EventEmitter<void>();

  constructor() {}
}
