import { Injectable } from '@angular/core';
import {
  combineLatest,
  from,
  lastValueFrom,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  finalize,
  map,
  shareReplay,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import {
  GlobalMediaSection,
  MediaSection,
  MEDIASECTIONS_PATH,
} from 'src/app/models/media-section.model';
import { MediaItem, MEDIA_PATH } from '../../../shared/media-item.outside';
import { AuthService } from '../auth.service';
import { ClientsService } from '../clients.service';
import { FirestoreService } from '../firestore.service';
import { NotificationsService } from '../notifications.service';
import { SentryService } from '../sentry.service';
import { StorageService } from '../storage.service';
import { safeToPromise } from 'src/app/utils/utils';
import imageCompression from 'browser-image-compression';

const MAX_FILE_SIZE = 50;

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  isUploading = false;
  uploadStateChangeEvent: Subject<void> = new Subject();

  uploadPercentage = 0;
  uploadPercentageChangeEvent: Subject<void> = new Subject();

  currentClientMediaItems$: Observable<Array<MediaItem>>;

  globalMediaItems$: Observable<Array<MediaItem>>;

  constructor(
    private notService: NotificationsService,
    private firestore: FirestoreService,
    private sentry: SentryService,
    private clients: ClientsService,
    private auth: AuthService,
    private storage: StorageService,
  ) {
    this.globalMediaItems$ = this.auth.user$.pipe(
      switchMap((user) => {
        if (!user) {
          return of([]);
        }

        return this.firestore.colWithIds$('media', (ref) =>
          ref.where('isGlobal', '==', true).orderBy('updatedAt', 'desc'),
        );
      }),
      map((data: Array<MediaItem>) => {
        const filteredData = data.filter((item) => !item.deleted);
        return filteredData;
      }),
      tap((data) => {
        this.sentry.logFirestoreRead('media', data.length);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      finalize(() => {}),
    );
    this.currentClientMediaItems$ = combineLatest([
      this.auth.user$,
      this.clients.currentClientId$,
    ]).pipe(
      map((value: [any, any]) => {
        return {
          user: value[0],
          clientId: value[1],
        };
      }),
      switchMap((value: any) => {
        if (!value.user) {
          return of([]);
        }

        return this.firestore
          .colWithIds$(`${'media'}`, (ref) =>
            ref
              .where('owner', '==', value.user.uid)
              .orderBy('updatedAt', 'desc'),
          )
          .pipe(
            map((items: Array<MediaItem>) => {
              if (!value.clientId) {
                return items;
              } else {
                const filtered = items.filter(
                  (item) => !item.client || item.client === value.clientId,
                );
                return filtered;
              }
            }),
          );

        return this.firestore.colWithIds$(`${'media'}`, (ref) =>
          ref
            .where('owner', '==', value.user.uid)
            .where('client', '==', value.clientId)
            .orderBy('updatedAt', 'desc'),
        );
        // return this.firestore.colWithIds$(`${'media'}`, ref => ref.where('owner', '==', value.user.uid).where('client', '==', value.clientId));
      }),
      map((data: Array<MediaItem>) => {
        const filteredData = data.filter((item) => !item.deleted);
        return filteredData;
      }),
      tap((data) => {
        this.sentry.logFirestoreRead('media', data.length);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      finalize(() => {}),
    );
  }

  private async compressFile(file: File): Promise<File> {
    if (!this.isAcceptedFormat(file)) {
      throw new Error(
        `Formato no soportado: ${file.type}. Por favor, sube solo imágenes (JPG, PNG, WebP) o GIFs`,
      );
    }

    // Si es un GIF, lo retornamos sin comprimir
    if (file.type === 'image/gif') {
      if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
        throw new Error(
          'GIF demasiado grande. Por favor, usa un GIF más pequeño.',
        );
      }
      return file;
    }

    const options = {
      maxSizeMB: MAX_FILE_SIZE - 0.25,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.8,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error compressing file:', error);
      throw new Error('Error al comprimir la imagen');
    }
  }

  async uploadFiles(files: Array<File>, isGlobal?: boolean) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (!this.isAcceptedFormat(file)) {
        this.notService.component.showErrorNotification({
          title: 'Formato no soportado',
          message: 'Por favor, sube solo imágenes (JPG, PNG, WebP) o GIFs',
        });
        return;
      }

      try {
        const processedFile = await this.compressFile(file);
        await this.uploadFile(processedFile, isGlobal);
      } catch (error) {
        console.error(error);
        this.notService.component.showErrorNotification({
          title: error.message || 'Error al procesar el archivo',
          message:
            'Por favor, intenta con un archivo más pequeño o contacta soporte',
        });
      }
    }
  }

  async uploadFile(file: File | any, isGlobal?: boolean) {
    const mediaItem: MediaItem = {};
    const currentUser = await safeToPromise(this.auth.user$.pipe(take(1)));
    const currentClientId = await safeToPromise(
      from(this.clients.currentClientId$).pipe(take(1)),
    );
    if (isGlobal) {
      if (currentUser.role !== 'admin') {
        this.notService.component.showErrorNotification({
          title: `No tienes permisos para subir archivos de este tipo`,
          message: 'Por favor, vuelve a intentarlo con otro archivo',
        });
        return;
      }
      mediaItem.isGlobal = true;
    } else {
      mediaItem.owner = currentUser.uid;
      mediaItem.client = currentClientId;
    }
    const folder = isGlobal ? 'global' : currentUser.uid;
    const mediaItemDocRef = await this.firestore.add('media', mediaItem);
    const mediaItemId = mediaItemDocRef.id;
    this.storage.startUpload(folder, mediaItemId, file);
    from(this.storage.percentage)
      .pipe(
        tap(
          (p) => console.log('Percentage: ', p),
          finalize(() => console.log('percentage observable finalized')),
        ),
      )
      .subscribe();
    await safeToPromise(from(this.storage.uploadTaskFinalized).pipe(take(1)), {
      // 5 minutes
      timeoutMs: 300000,
    });
    console.log('Upload finished');
  }

  // NOTE: apparently not used anywhere
  async uploadFileGlobal(file: File | any) {
    const mediaItem: MediaItem = {};
    const currentUser = await safeToPromise(this.auth.user$.pipe(take(1)));
    mediaItem.owner = currentUser.uid;
    const mediaItemDocRef = await this.firestore.add('media', mediaItem);
    const mediaItemId = mediaItemDocRef.id;
    this.storage.startUpload(currentUser.uid, mediaItemId, file);
    from(this.storage.percentage)
      .pipe(
        tap(
          (p) => console.log('Percentage: ', p),
          finalize(() => console.log('percentage observable finalized')),
        ),
      )
      .subscribe();
    await safeToPromise(from(this.storage.uploadTaskFinalized).pipe(take(1)));
    // return mediaIt;
  }

  isAcceptedFormat(file: File) {
    console.log('isAcceptedFormat', file);
    try {
      const acceptedTypes = [
        'image/jpeg',
        'image/png',
        'image/webp',
        'image/gif',
      ];
      return acceptedTypes.includes(file.type);
    } catch (error) {
      return false;
    }
  }

  isAcceptedSize(file: File) {
    try {
      const size = file.size;
      return size <= MAX_FILE_SIZE * 1024 * 1024;
    } catch (error) {
      return false;
    }
  }

  async deleteMediaItem(mediaItem: MediaItem) {
    mediaItem.deleted = true;
    await this.firestore.update(`${MEDIA_PATH}/${mediaItem.id}`, mediaItem);
  }

  async getMediaItem(mediaItemId: string) {
    const result = await safeToPromise(
      this.firestore
        .docWithId$<MediaItem>(`${MEDIA_PATH}/${mediaItemId}`)
        .pipe(take(1)),
    );
    return result;
  }

  async getMediaItemWhenFullyUploaded(mediaItemId: string) {
    const result = await safeToPromise(
      this.firestore
        .docWithId$<MediaItem>(`${MEDIA_PATH}/${mediaItemId}`)
        .pipe(takeWhile((value) => !!value.downloadURL)),
    );
    return result;
  }

  async getSectionsOrCreateSections(owner: string) {
    let section = await this.getSections(owner);
    if (!section) {
      section = { owner, sections: [] };
      await this.firestore.add(`${MEDIASECTIONS_PATH}`, section);
      section = await this.getSections(owner);
    }
    return section;
  }

  async updateSections(section: MediaSection | GlobalMediaSection) {
    await this.firestore.update(`${MEDIASECTIONS_PATH}/${section.id}`, section);
    return true;
  }

  async getSections(owner: string) {
    const docs: MediaSection[] = await safeToPromise(
      this.firestore
        .colWithIds$<MediaSection>(`${MEDIASECTIONS_PATH}`, (ref) =>
          ref.where('owner', '==', owner),
        )
        .pipe(take(1)),
    );
    if (docs.length < 1) {
      return undefined;
    }
    return docs[0];
  }

  async getGlobalSections() {
    const docs: GlobalMediaSection[] = await lastValueFrom(
      this.firestore
        .colWithIds$<GlobalMediaSection>(`${MEDIASECTIONS_PATH}`, (ref) =>
          ref.where('isGlobal', '==', true),
        )
        .pipe(take(1)),
    );

    if (docs.length < 1) {
      return undefined;
    }
    return docs[0];
  }
}
