import { segmentsMap } from "./training-avatar.shared";

export const EXERCISES_PATH = "exercises";

export const correctiveSegmentOptions = [
  { label: "Raquis", value: "raquis" },
  { label: "Postura", value: "postura" },
  { label: "Articulaciones", value: "articulaciones" },
] as const;

export const materialOptions = [
  { label: "Peso Corporal", value: "pesoCorporal" },
  { label: "Peso Libre", value: "pesoLibre" },
  { label: "Máquinas", value: "maquinas" },
  { label: "Polea", value: "polea" },
  { label: "Elásticos", value: "elasticos" },
  { label: "Foam", value: "foam" },
  { label: "KB", value: "kb" },
  { label: "Cardio", value: "cardio" },
  { label: "Core", value: "core" },
] as const;

// Base interface with common fields
interface BaseExercise {
  id: string;
  title: string;
  owner?: string; // if isGlobal is true, owner is not used
  description?: string;
  leftRight?: boolean;
  isGlobal?: boolean;
  imageUrl?: string;
  material?: (typeof materialOptions)[number]["value"][];
  createdAt?: Date;
  updatedAt?: Date;
}

// Training exercise interface
export interface TrainingExercise extends BaseExercise {
  type: "training";
  action?: keyof typeof segmentsMap;
  level?: number[];
  trainingObjective?: string; // keyword for search
}

// Corrective exercise interface
export interface CorrectiveExercise extends BaseExercise {
  type: "corrective";
  segment?: (typeof correctiveSegmentOptions)[number]["value"]; // dropdown
  correctiveObjective?: string; // keyword for search
}

// Union type for all exercise types
export type Exercise = TrainingExercise | CorrectiveExercise;
