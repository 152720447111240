<div class="">
  <div *ngIf="clientCorrectiveSession" class="test-view">
    <div *ngIf="client$ | async as client">
      <div class="app-container" *ngIf="correctiveSessionProtocolDef">
        <app-test-header
          [title]="correctiveSessionProtocolDef.name"
          [isUserClientView]="isUserClientView"
          [generateReportAction]="generateReport"
        ></app-test-header>

        <div class="test-info">
          <app-client-back-button
            *ngIf="!isUserClientView"
            [client]="client"
            [myVafMode]="true"
            class="mr-2"
          ></app-client-back-button>
          <div class="date-selector mt-2 md:mt-0">
            <button
              *ngIf="!isUserClientView"
              (click)="picker.open()"
              class="date-selector__button"
            >
              {{ clientCorrectiveSession.createdAt.toDate() | date }}
              <i class="fas fa-edit"></i>
            </button>
            <span *ngIf="isUserClientView">
              {{ clientCorrectiveSession.createdAt.toDate() | date }}
            </span>

            <input
              style="display: none"
              (dateChange)="onDateChanged($event)"
              matInput
              [matDatepicker]="picker"
              placeholder="Choose a date"
            />
            <mat-datepicker
              touchUi
              #picker
              [startAt]="clientCorrectiveSession.createdAt.toDate()"
            ></mat-datepicker>
          </div>
        </div>
        <ng-container *ngIf="initDone">
          <div
            *ngFor="
              let sectionDef of correctiveSessionProtocolDef.sections;
              let sectionIndex = index
            "
            class="mb-5 rounded-lg bg-white py-4 pb-8 transition-all duration-300"
          >
            <div
              class="flex items-center px-3 text-center text-lg font-semibold md:px-8 md:text-left"
              [ngClass]="
                getRoundsBySection(sectionIndex) > 1
                  ? 'justify-between'
                  : 'justify-center'
              "
            >
              <span class="py-1">
                {{ sectionDef.name }}
              </span>

              <!-- rounds -->
              <div
                *ngIf="getRoundsBySection(sectionIndex) > 1"
                class="rounded-md bg-inteccLight px-2 py-1 text-inteccDark"
              >
                {{ 'X' + getRoundsBySection(sectionIndex) }}
                <span
                  matTooltip="Vueltas al circuito"
                  #tooltip="matTooltip"
                  (click)="tooltip.toggle(); $event.stopPropagation()"
                  class="ml-0.5 cursor-pointer"
                >
                  <i class="fas fa-circle-info"></i>
                </span>
              </div>
            </div>
            <div class="mb-4"></div>
            <!-- images -->
            <!-- For old version without exercises entity -->
            <ng-container
              *ngIf="
                sectionDef.fixedImages && sectionDef.fixedImages.length > 0
              "
            >
              <div
                class="custom-scroll relative flex w-full gap-x-5 overflow-y-hidden overflow-x-scroll px-5 pb-3 md:px-8"
              >
                <ng-container *ngFor="let image of sectionDef.fixedImages">
                  <div
                    class="h-screen-3/4 max-h-[300px] w-full flex-shrink-0 sm:max-h-80 sm:w-auto"
                  >
                    <div
                      class="relative mx-auto aspect-square h-full overflow-hidden rounded-md bg-gray-100"
                    >
                      <img
                        *ngIf="image.downloadUrl"
                        src="{{ image.downloadUrl }}"
                        class="h-full w-full object-cover"
                        alt="{{ image.title }}"
                      />
                      <div
                        class="flex h-full w-full items-center justify-center"
                        *ngIf="!image.downloadUrl"
                      >
                        La imagen no tiene url
                      </div>
                      <div
                        *ngIf="image.title"
                        class="absolute left-0 top-0 w-full bg-black/70 px-2 py-3 text-center text-sm font-bold text-white"
                      >
                        <p>{{ image.title }}</p>
                      </div>
                      <div
                        class="absolute bottom-0 right-0 px-4 py-5"
                        *ngIf="!isUserClientView"
                      >
                        <a
                          *ngIf="image.link"
                          href="{{ image.link }}"
                          target="_blank"
                          class="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/70 text-white"
                        >
                          <i class="fas fa-external-link-alt text-white"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <!-- For new version with exercises entity -->
            <ng-container *ngIf="sectionDef.sectionExercises?.length">
              <div
                class="custom-scroll relative flex w-full !cursor-default gap-x-6 overflow-y-hidden overflow-x-scroll pb-3"
              >
                <ng-container
                  *ngFor="let exercise of sectionDef.sectionExercises"
                >
                  <div
                    *ngIf="
                      getExerciseDetails(exercise.exerciseId) as exerciseDetails
                    "
                    class="w-full flex-shrink-0 sm:w-auto"
                  >
                    <!-- previous class -->
                    <!-- class="mx-auto max-w-[300px] overflow-hidden rounded-md bg-gray-100 md:max-w-80" -->
                    <div
                      class="relative mx-auto w-full max-w-[340px] rounded-md bg-gray-100 md:w-auto md:max-w-80"
                    >
                      <!-- Title tooltip -->
                      <div
                        class="overflow-hidden text-ellipsis whitespace-nowrap bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                        [matTooltip]="exerciseDetails.title"
                        #titleTooltip="matTooltip"
                        (click)="
                          titleTooltip.toggle(); $event.stopPropagation()
                        "
                      >
                        {{ exerciseDetails.title }}
                      </div>
                      <div class="aspect-square max-h-[340px]">
                        <img
                          *ngIf="exerciseDetails.imageUrl"
                          src="{{ exerciseDetails.imageUrl }}"
                          class="h-full w-full object-cover"
                          alt="{{ exerciseDetails.title }}"
                        />
                      </div>

                      <div
                        class="flex h-full w-full items-center justify-center"
                        *ngIf="!exerciseDetails.imageUrl"
                      >
                        Ejercicio sin imagen
                      </div>
                      <!-- <div
                        *ngFor="let field of sectionDef.fields; let i = index"
                        class="h-9 bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                      >
                        {{ field.title }}:
                        {{
                          field.type === 'select'
                            ? getFieldOptionText(sectionDef, field, exercise)
                            : getValueForExercise(
                                sectionDef.localId,
                                field.localId,
                                exercise.key
                              )
                        }}
                      </div> -->
                      <div class="grid grid-cols-4">
                        <!-- Casilla de series -->
                        <div
                          class="col-span-1 h-9 border-r border-gray-500 bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                          [matTooltip]="sectionDef.fields[0].title"
                          #seriesToolTip="matTooltip"
                          (click)="
                            seriesToolTip.toggle(); $event.stopPropagation()
                          "
                        >
                          <span *ngIf="sectionDef.fields[0] as field">
                            {{
                              getValueForExercise(
                                sectionDef.localId,
                                field.localId,
                                exercise.key
                              )
                                ? 'X' +
                                  getValueForExercise(
                                    sectionDef.localId,
                                    field.localId,
                                    exercise.key
                                  )
                                : '-'
                            }}
                          </span>
                        </div>
                        <!-- Casilla Volumen -->
                        <div
                          class="col-span-3 h-9 bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                          [matTooltip]="sectionDef.fields[1].title"
                          #volumeTooltip="matTooltip"
                          (click)="
                            volumeTooltip.toggle(); $event.stopPropagation()
                          "
                        >
                          <span *ngIf="sectionDef.fields[1] as field">
                            {{
                              (
                                field.type === 'select'
                                  ? getFieldOptionText(
                                      sectionDef,
                                      field,
                                      exercise
                                    )
                                  : getValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                              )
                                ? field.type === 'select'
                                  ? getFieldOptionText(
                                      sectionDef,
                                      field,
                                      exercise
                                    )
                                  : getValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                                : '-'
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="grid grid-cols-4">
                        <!-- Casilla intensidad -->
                        <div
                          class="col-span-2 h-9 border-y border-gray-500 bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                          [matTooltip]="sectionDef.fields[2].title"
                          #intensityTooltip="matTooltip"
                          (click)="
                            intensityTooltip.toggle(); $event.stopPropagation()
                          "
                        >
                          <span *ngIf="sectionDef.fields[2] as field">
                            {{
                              (
                                field.type === 'select'
                                  ? getFieldOptionText(
                                      sectionDef,
                                      field,
                                      exercise
                                    )
                                  : getValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                              )
                                ? field.type === 'select'
                                  ? getFieldOptionText(
                                      sectionDef,
                                      field,
                                      exercise
                                    )
                                  : getValueForExercise(
                                      sectionDef.localId,
                                      field.localId,
                                      exercise.key
                                    )
                                : '-'
                            }}
                          </span>
                        </div>
                        <!-- Casilla descanso series -->
                        <div
                          class="col-span-2 h-9 border-y border-l border-gray-500 bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                          [matTooltip]="sectionDef.fields[3].title"
                          #seriesRestTooltip="matTooltip"
                          (click)="
                            seriesRestTooltip.toggle(); $event.stopPropagation()
                          "
                        >
                          <span *ngIf="sectionDef.fields[3] as field">
                            {{
                              getValueForExercise(
                                sectionDef.localId,
                                field.localId,
                                exercise.key
                              )
                                ? getValueForExercise(
                                    sectionDef.localId,
                                    field.localId,
                                    exercise.key
                                  ) + '"'
                                : '-'
                            }}
                          </span>
                        </div>
                      </div>
                      <!-- Casilla campo extra -->
                      <div
                        *ngIf="sectionDef.fields[5] as field"
                        class="h-9 border-b border-gray-500 bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white"
                        [matTooltip]="sectionDef.fields[5].title"
                        #tooltip="matTooltip"
                        (click)="tooltip.toggle(); $event.stopPropagation()"
                      >
                        {{ field.title }}:
                        <span>
                          {{
                            field.type === 'select'
                              ? getFieldOptionText(sectionDef, field, exercise)
                              : getValueForExercise(
                                  sectionDef.localId,
                                  field.localId,
                                  exercise.key
                                )
                          }}
                        </span>
                      </div>
                      <!-- Casilla descanso ejercicio -->
                      <div
                        *ngIf="sectionDef.fields[4] as field"
                        class="absolute right-0 top-1/2 z-10 h-9 w-12 -translate-y-1/2 rounded-md border border-gray-500 bg-inteccDark px-2 py-2 text-center text-sm font-bold text-white md:left-full md:-translate-x-3"
                        [matTooltip]="sectionDef.fields[4].title"
                        #tooltip="matTooltip"
                        (click)="tooltip.toggle(); $event.stopPropagation()"
                      >
                        <span>
                          {{
                            getValueForExercise(
                              sectionDef.localId,
                              field.localId,
                              exercise.key
                            )
                              ? getValueForExercise(
                                  sectionDef.localId,
                                  field.localId,
                                  exercise.key
                                ) + '"'
                              : '-'
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <!-- controles de play/pause, swipper... (si se implementa) -->
              <!-- TODO:: uncomment when adding controls -->
              <!-- <div
                class="mx-auto mt-2 flex h-9 w-full max-w-sm justify-center gap-x-2 font-bold text-white"
              >
                <button
                  class="flex grow items-center justify-center rounded-md bg-inteccLight px-2 text-inteccDark hover:bg-inteccLight/80"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button
                  class="flex grow items-center justify-center rounded-md bg-inteccLight px-2 text-inteccDark hover:bg-inteccLight/80"
                >
                  <i class="fas fa-play"></i>
                </button>
                <button
                  class="flex grow items-center justify-center rounded-md bg-inteccLight px-2 text-inteccDark hover:bg-inteccLight/80"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div> -->
            </ng-container>
            <div class="mb-4"></div>
            <div class="px-2 md:px-4">
              <div
                class="mb-2 flex w-full justify-between border-b text-sm sm:justify-start md:text-base"
              >
                <app-tab-item
                  [name]="'Descripción'"
                  [selected]="selectedTab === 'description'"
                  (tapEvent)="handleTabChange('description')"
                ></app-tab-item>
                <app-tab-item
                  [name]="'Prescripción'"
                  [selected]="selectedTab === 'prescription'"
                  (tapEvent)="handleTabChange('prescription')"
                ></app-tab-item>
                <app-tab-item
                  [name]="'Comentarios'"
                  [selected]="selectedTab === 'comments'"
                  (tapEvent)="handleTabChange('comments')"
                ></app-tab-item>
              </div>
              <!-- Descripción -->
              <div *ngIf="selectedTab === 'description'" class="group">
                <div *ngIf="sectionDef.description" class="px-1 py-4">
                  <p
                    class="text-neutral-500"
                    [innerHTML]="processLineBreak(sectionDef.description)"
                  ></p>
                </div>
              </div>
              <!-- Prescrición -->
              <div *ngIf="selectedTab === 'prescription'" class="group">
                <div class="px-1 py-4">
                  <div *ngIf="sectionDef.mode === 'table'" class="w-full">
                    <div *ngIf="sectionDef.fields; else noFields">
                      <div class="col-span-2 overflow-y-auto overflow-x-scroll">
                        <div
                          *ngIf="sectionDef.tableMode === 'xy'"
                          class="h-auto self-start rounded-md"
                        >
                          <div class="flex items-center">
                            <label class="mr-4 font-semibold" for="rounds"
                              >Vueltas</label
                            >
                            <input
                              class="w-20 rounded-lg border-2 border-neutral-200 px-1"
                              type="number"
                              [min]="1"
                              [max]="50"
                              id="rounds"
                              [ngModel]="getRoundsBySection(sectionIndex)"
                              (ngModelChange)="
                                setRoundsBySection(sectionIndex, $event)
                              "
                            />
                            <i
                              class="fa-solid fa-circle-info ml-2 cursor-pointer text-gray-500"
                              matTooltip="Pon más de 1 vuelta para que sea circuito"
                              #tooltip="matTooltip"
                              (click)="
                                tooltip.toggle(); $event.stopPropagation()
                              "
                            ></i>
                          </div>
                          <table
                            mat-table
                            [dataSource]="
                              preBuiltDataSources[sectionDef.localId]
                            "
                            class="w-full table-fixed"
                          >
                            <ng-container matColumnDef="rowTitle">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="font-bold"
                                style="width: 220px"
                              >
                                {{
                                  sectionDef.tableFirstColTitle
                                    ? sectionDef.tableFirstColTitle
                                    : ' '
                                }}
                              </th>
                              <td
                                mat-cell
                                *matCellDef="let element"
                                style="
                                  border-bottom: 1px solid #e5e7eb;
                                  min-width: 100px;
                                "
                              >
                                <p class="w-full font-bold">
                                  {{ element.rowTitle }}
                                </p>
                              </td>
                            </ng-container>
                            <!-- for old version without exercises entity -->
                            <ng-container *ngIf="!sectionDef.sectionExercises">
                              <ng-container
                                *ngFor="let field of sectionDef.fields"
                                matColumnDef="{{ field.localId }}"
                              >
                                <th
                                  mat-header-cell
                                  *matHeaderCellDef
                                  class="font-bold"
                                >
                                  {{ field.title }}
                                </th>
                                <td
                                  mat-cell
                                  *matCellDef="let element"
                                  style="
                                    border-bottom: 1px solid #e5e7eb;
                                    overflow: visible;
                                    min-width: 100px;
                                  "
                                >
                                  <ng-container *ngIf="!isUserClientView">
                                    <app-test-field-dropdown
                                      [tableMode]="true"
                                      [mode]="field.type"
                                      [options]="field.options"
                                      [text]="element[field.localId]"
                                      [selectedIndex]="
                                        element.sectionExerciseKey
                                          ? getValueForExercise(
                                              sectionDef.localId,
                                              field.localId,
                                              element.sectionExerciseKey
                                            )
                                          : getValueTable(
                                              sectionDef.localId,
                                              field.localId,
                                              element.index
                                            )
                                      "
                                      [openToTop]="false"
                                      (selectedItemChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                      (textChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                    >
                                    </app-test-field-dropdown>
                                  </ng-container>
                                  <ng-container *ngIf="isUserClientView">
                                    <p *ngIf="field.type === 'select'">
                                      {{
                                        getFieldOptionText(
                                          sectionDef,
                                          field,
                                          element
                                        )
                                      }}
                                    </p>
                                    <p *ngIf="field.type === 'text'">
                                      {{ element[field.localId] }}
                                    </p>
                                  </ng-container>
                                </td>
                              </ng-container>
                            </ng-container>

                            <!-- for new version with exercises entity -->
                            <ng-container *ngIf="sectionDef.sectionExercises">
                              <ng-container
                                *ngFor="let field of sectionDef.fields"
                                matColumnDef="{{ field.localId }}"
                              >
                                <th
                                  mat-header-cell
                                  *matHeaderCellDef
                                  class="font-bold"
                                >
                                  {{ field.title }}
                                </th>
                                <td
                                  mat-cell
                                  *matCellDef="let element"
                                  style="
                                    border-bottom: 1px solid #e5e7eb;
                                    overflow: visible;
                                    min-width: 100px;
                                  "
                                >
                                  <ng-container *ngIf="!isUserClientView">
                                    <app-test-field-dropdown
                                      [tableMode]="true"
                                      [mode]="field.type"
                                      [options]="field.options"
                                      [text]="element[field.localId]"
                                      [selectedIndex]="
                                        element.sectionExerciseKey
                                          ? getValueForExercise(
                                              sectionDef.localId,
                                              field.localId,
                                              element.sectionExerciseKey
                                            )
                                          : getValueTable(
                                              sectionDef.localId,
                                              field.localId,
                                              element.index
                                            )
                                      "
                                      [openToTop]="false"
                                      (selectedItemChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                      (textChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                    >
                                    </app-test-field-dropdown>
                                  </ng-container>
                                  <ng-container *ngIf="isUserClientView">
                                    <p *ngIf="field.type === 'select'">
                                      {{
                                        getFieldOptionText(
                                          sectionDef,
                                          field,
                                          element
                                        )
                                      }}
                                    </p>
                                    <p *ngIf="field.type === 'text'">
                                      {{
                                        getValueForExercise(
                                          sectionDef.localId,
                                          field.localId,
                                          element.sectionExerciseKey
                                        )
                                      }}
                                    </p>
                                  </ng-container>
                                </td>
                              </ng-container>
                            </ng-container>

                            <tr
                              mat-header-row
                              *matHeaderRowDef="
                                preBuiltDisplayedColumns[sectionDef.localId]
                              "
                            ></tr>
                            <tr
                              mat-row
                              *matRowDef="
                                let row;
                                columns: preBuiltDisplayedColumns[
                                  sectionDef.localId
                                ]
                              "
                            ></tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <ng-template #noFields> </ng-template>
                  </div>
                </div>
              </div>
              <!-- Comentarios -->
              <div *ngIf="selectedTab === 'comments'" class="group">
                <div class="px-1 py-4">
                  <textarea
                    class="w-full rounded-lg border-2 border-neutral-200"
                    #commentsTextArea
                    name="comments"
                    cols="30"
                    rows="5"
                    [value]="getComments(sectionDef)"
                    (change)="onCommentsChanged(sectionDef, $event)"
                    [readonly]="isUserClientView"
                  ></textarea>
                </div>
              </div>
            </div>

            <!-- NOTE: Old, TODO: remove if the other (the one with tabs) is fine for Julian -->
            <!-- Descripción -->
            <div class="hidden">
              <details class="group">
                <summary
                  class="no-details-marker flex cursor-pointer items-center rounded-md p-4 hover:bg-gray-50"
                >
                  <i
                    class="fas fa-chevron-down text-xl text-intecc transition-transform group-open:rotate-180"
                  ></i>
                  <span class="ml-4 text-lg font-semibold">Descripción</span>
                </summary>
                <div *ngIf="sectionDef.description" class="border-t px-1 py-4">
                  <p
                    class="text-neutral-500"
                    [innerHTML]="processLineBreak(sectionDef.description)"
                  ></p>
                </div>
              </details>
              <!-- Prescrición -->
              <details class="group mt-4">
                <summary
                  class="no-details-marker flex cursor-pointer items-center rounded-md p-4 hover:bg-gray-50"
                >
                  <i
                    class="fas fa-chevron-down text-xl text-intecc transition-transform group-open:rotate-180"
                  ></i>
                  <span class="ml-4 text-lg font-semibold">Prescripción</span>
                </summary>
                <div class="border-t px-1 py-4">
                  <div *ngIf="sectionDef.mode === 'table'" class="w-full">
                    <!-- Old class for bellow element -->
                    <!-- class="grid grid-cols-3 md:grid-cols-1.2fr-1fr-1fr md:grid-flow-col md:auto-cols-fr grid-rows-100-of-parent md:grid-rows-1 gap-x-5 table-container max-h-screen-3/4 md:h-auto {{
                }}" -->
                    <div *ngIf="sectionDef.fields; else noFields">
                      <div class="col-span-2 overflow-y-auto overflow-x-scroll">
                        <div
                          *ngIf="sectionDef.tableMode === 'xy'"
                          style=""
                          class="h-auto self-start rounded-md"
                        >
                          <table
                            mat-table
                            [dataSource]="
                              preBuiltDataSources[sectionDef.localId]
                            "
                            class="w-full table-fixed"
                          >
                            <ng-container matColumnDef="rowTitle">
                              <th
                                mat-header-cell
                                *matHeaderCellDef
                                class="font-bold"
                                style="width: 220px"
                              >
                                {{
                                  sectionDef.tableFirstColTitle
                                    ? sectionDef.tableFirstColTitle
                                    : ' '
                                }}
                              </th>
                              <td
                                mat-cell
                                *matCellDef="let element"
                                style="
                                  border-bottom: 1px solid #e5e7eb;
                                  min-width: 100px;
                                "
                              >
                                <p class="w-full font-bold">
                                  {{ element.rowTitle }}
                                </p>
                              </td>
                            </ng-container>
                            <!-- for old version without exercises entity -->
                            <ng-container *ngIf="!sectionDef.sectionExercises">
                              <ng-container
                                *ngFor="let field of sectionDef.fields"
                                matColumnDef="{{ field.localId }}"
                              >
                                <th
                                  mat-header-cell
                                  *matHeaderCellDef
                                  class="font-bold"
                                >
                                  {{ field.title }}
                                </th>
                                <td
                                  mat-cell
                                  *matCellDef="let element"
                                  style="
                                    border-bottom: 1px solid #e5e7eb;
                                    overflow: visible;
                                    min-width: 100px;
                                  "
                                >
                                  <ng-container *ngIf="!isUserClientView">
                                    <app-test-field-dropdown
                                      [tableMode]="true"
                                      [mode]="field.type"
                                      [options]="field.options"
                                      [text]="element[field.localId]"
                                      [selectedIndex]="
                                        element.sectionExerciseKey
                                          ? getValueForExercise(
                                              sectionDef.localId,
                                              field.localId,
                                              element.sectionExerciseKey
                                            )
                                          : getValueTable(
                                              sectionDef.localId,
                                              field.localId,
                                              element.index
                                            )
                                      "
                                      [openToTop]="false"
                                      (selectedItemChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                      (textChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                    >
                                    </app-test-field-dropdown>
                                  </ng-container>
                                  <ng-container *ngIf="isUserClientView">
                                    <p *ngIf="field.type === 'select'">
                                      {{
                                        getFieldOptionText(
                                          sectionDef,
                                          field,
                                          element
                                        )
                                      }}
                                    </p>
                                    <p *ngIf="field.type === 'text'">
                                      {{ element[field.localId] }}
                                    </p>
                                  </ng-container>
                                </td>
                              </ng-container>
                            </ng-container>

                            <!-- for new version with exercises entity -->
                            <ng-container *ngIf="sectionDef.sectionExercises">
                              <ng-container
                                *ngFor="let field of sectionDef.fields"
                                matColumnDef="{{ field.localId }}"
                              >
                                <th
                                  mat-header-cell
                                  *matHeaderCellDef
                                  class="font-bold"
                                >
                                  {{ field.title }}
                                </th>
                                <td
                                  mat-cell
                                  *matCellDef="let element"
                                  style="
                                    border-bottom: 1px solid #e5e7eb;
                                    overflow: visible;
                                    min-width: 100px;
                                  "
                                >
                                  <ng-container *ngIf="!isUserClientView">
                                    <app-test-field-dropdown
                                      [tableMode]="true"
                                      [mode]="field.type"
                                      [options]="field.options"
                                      [text]="element[field.localId]"
                                      [selectedIndex]="
                                        element.sectionExerciseKey
                                          ? getValueForExercise(
                                              sectionDef.localId,
                                              field.localId,
                                              element.sectionExerciseKey
                                            )
                                          : getValueTable(
                                              sectionDef.localId,
                                              field.localId,
                                              element.index
                                            )
                                      "
                                      [openToTop]="false"
                                      (selectedItemChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                      (textChanged)="
                                        onValueChanged(
                                          $event,
                                          element.sectionExerciseKey,
                                          sectionDef.localId,
                                          field.localId,
                                          element.index
                                        )
                                      "
                                    >
                                    </app-test-field-dropdown>
                                  </ng-container>
                                  <ng-container *ngIf="isUserClientView">
                                    <p *ngIf="field.type === 'select'">
                                      {{
                                        getFieldOptionText(
                                          sectionDef,
                                          field,
                                          element
                                        )
                                      }}
                                    </p>
                                    <p *ngIf="field.type === 'text'">
                                      {{
                                        getValueForExercise(
                                          sectionDef.localId,
                                          field.localId,
                                          element.sectionExerciseKey
                                        )
                                      }}
                                    </p>
                                  </ng-container>
                                </td>
                              </ng-container>
                            </ng-container>

                            <tr
                              mat-header-row
                              *matHeaderRowDef="
                                preBuiltDisplayedColumns[sectionDef.localId]
                              "
                            ></tr>
                            <tr
                              mat-row
                              *matRowDef="
                                let row;
                                columns: preBuiltDisplayedColumns[
                                  sectionDef.localId
                                ]
                              "
                            ></tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <ng-template #noFields> </ng-template>
                  </div>
                </div>
              </details>
              <!-- Comentarios -->
              <details class="group mt-4">
                <summary
                  class="no-details-marker flex cursor-pointer items-center rounded-md p-4 hover:bg-gray-50"
                >
                  <i
                    class="fas fa-chevron-down text-xl text-intecc transition-transform group-open:rotate-180"
                  ></i>
                  <span class="ml-4 text-lg font-semibold">Comentarios</span>
                </summary>
                <div class="border-t px-1 py-4">
                  <textarea
                    class="w-full rounded-lg border-2 border-neutral-200"
                    #commentsTextArea
                    name="comments"
                    cols="30"
                    rows="5"
                    [value]="getComments(sectionDef)"
                    (change)="onCommentsChanged(sectionDef, $event)"
                    [readonly]="isUserClientView"
                  ></textarea>
                </div>
              </details>
            </div>
          </div>

          <div
            class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
          >
            <div class="mb-4 flex justify-between">
              <p class="text-xl font-semibold">Recomendaciones y enlaces</p>
              <div class="flex items-center"></div>
            </div>
            <div class="w-full overflow-y-scroll md:overflow-y-hidden"></div>
            <div class="w-full">
              <p class="mb-4 mt-7 text-lg font-semibold">Recomendaciones</p>
              <textarea
                class="w-full rounded-lg border-2 border-neutral-200"
                #commentsTextArea
                name="comments"
                cols="30"
                rows="5"
                [value]="getTips()"
                (change)="onTipsChanged($event)"
                [readonly]="isUserClientView"
              ></textarea>
            </div>
            <div class="w-full">
              <p class="mb-1 mt-7 text-lg font-semibold">Enlaces</p>
              <p *ngIf="!isUserClientView">Añade enlaces a los informes</p>
              <app-test-report-links
                [links]="clientCorrectiveSession.links"
                (changeEvent)="this.setLinks($event)"
                [isUserClientView]="isUserClientView"
              ></app-test-report-links>
            </div>
          </div>
          <div
            *ngIf="!isUserClientView"
            class="mb-5 rounded-lg bg-white px-5 py-4 pb-8 transition-all duration-300 md:px-8"
          >
            <div class="mb-4">
              <p class="mb-4 text-xl font-semibold">Enlace para compartir</p>
              <app-input
                class="md:col-span-2"
                [readonly]="true"
                [button]="copySharedUrlButton"
                [value]="getShareURL()"
              ></app-input>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
