<div
  class="sm-backdrop"
  [class.sm-backdrop--is-open]="isOpen"
  (click)="onBackdropClick($event)"
  [style.z-index]="config.extraZIndex ? 1000 + config.extraZIndex : 1000"
>
  <div
    class="sm-content"
    [class.sm-content--left-small]="config.slideConfig === 'left-small'"
    [class.sm-content--right-small]="config.slideConfig === 'right-small'"
    [class.sm-content--is-open]="isOpen"
  >
    <ng-template appModalHost></ng-template>
  </div>
</div>
