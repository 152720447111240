import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EditedImageViewComponent } from 'src/app/components/edited-image-view/edited-image-view.component';
import { Client } from 'src/app/models/client.model';
import { InclinometerSubscriber } from 'src/app/models/inclinometer-subscriber.interface';
import {
  GlobalMediaSection,
  MediaSection,
  MediaSectionItem,
} from 'src/app/models/media-section.model';
import { EditedMediaItem } from 'src/app/models/media/edited-media-item.model';
import { Modal } from 'src/app/models/modal.model';
import { AuthService } from 'src/app/services/auth.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { MediaEditService } from 'src/app/services/media/media-edit.service';
import { MediaService } from 'src/app/services/media/media.service';
import { ModalService } from 'src/app/services/modal.service';
import { NewInclinometerService } from 'src/app/services/new-inclinometer.service';
import { UserConfigServiceService } from 'src/app/services/user-config-service.service';
import { bytesToMB, dataURItoBlob, sleep } from 'src/app/utils/utils';
import { MediaItem } from '../../../../../Server/functions/src/shared/media-item.model';
import { TestImageValue } from '../../../../../Server/functions/src/shared/test-images.shared';
import { ConfirmActionDialogService } from 'src/app/services/confirm-action-dialog.service';

const STORAGE_LIMIT = 500;

@Component({
  selector: 'app-client-media',
  templateUrl: './client-media.component.html',
  styleUrls: ['./client-media.component.scss'],
})
export class ClientMediaComponent
  implements OnInit, OnDestroy, Modal, InclinometerSubscriber
{
  onClose = new Subject<any>();
  data: ClientMediaModalData;

  isLoading = false;
  noMediaItemsYet = true;
  noEditedMediaItemsYet = true;
  tabState: 'items' | 'item' | 'capture' = 'items';
  selectedItemType: 'original' | 'edited';
  captureState: 'capture' | 'view';
  uploadingCapture = false;
  selectedItemsTab: 'original' | 'edited' | 'Galería INTECC' = 'original';
  copyURLState: 'default' | 'copied' = 'default';

  isHoveringDropzone = false;
  filesToUpload: File[] = [];
  globalMediaItemsSubscription: Subscription;
  globalMediaItems: Array<MediaItem>;
  currentClientMediaItemsSubscription: Subscription;
  currentClientMediaItems: Array<MediaItem>;
  currentClientEditedMediaItemsSubscription: Subscription;
  currentClientEditedMediaItems: Array<EditedMediaItem>;
  selectedItem: MediaItem | EditedMediaItem = null;
  usedStorageLeftMB$: Observable<number>;
  usedStoragePercentage$: Observable<number>;

  renderingEditedImage = false;

  @ViewChild('video', { static: false })
  videoElementRef: ElementRef;

  @ViewChild('canvas', { static: false })
  canvas: ElementRef;

  @ViewChild('photoview ', { static: false })
  photoViewElementRef: ElementRef;

  videoStream: MediaStream;

  currentAngle = 0;
  inclinationOK = false;

  @ViewChild('editedImageView', { static: false })
  editedImageView: EditedImageViewComponent;

  returnValue: any;

  sections: MediaSection;

  globalSections: GlobalMediaSection;

  moveProtocolDialogOpen = false;
  moveGlobalDialogOpen = false;

  selectedMediaToMove: string;

  editSectionNameSection: MediaSectionItem;
  editSectionNameOpen = false;
  editSectionNameText = 'asdg';

  multiSelectList: string[] = [];

  userIsAdmin = false;

  constructor(
    private auth: AuthService,
    private confirmActionDialogService: ConfirmActionDialogService,
    private mediaService: MediaService,
    private mediaEditService: MediaEditService,
    private userConfig: UserConfigServiceService,
    private inclinometerService: NewInclinometerService,
    private renderer: Renderer2,
    private modalService: ModalService,
    private functions: FunctionsService,
  ) {}

  onNewAngle(angle: number) {
    this.currentAngle = angle;
    this.inclinationOK = this.isInclinationOK(angle);
  }

  isInclinationOK(angle: number) {
    const margin = 1;
    if (angle < margin || angle > 360 - margin) {
      return true;
    }
    if (angle > 90 - margin && angle < 90 + margin) {
      return true;
    }
    return false;
  }

  historyBackClose() {
    this.onClose.next(this.returnValue);
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.globalMediaItemsSubscription = this.mediaService.globalMediaItems$
      .pipe(
        tap((result) => {
          this.loadGlobalMediaItems(result);
        }),
      )
      .subscribe();
    this.currentClientMediaItemsSubscription =
      this.mediaService.currentClientMediaItems$
        .pipe(
          tap((result) => {
            this.loadMediaItems(result);
          }),
        )
        .subscribe();
    this.currentClientEditedMediaItemsSubscription =
      this.mediaEditService.currentClientEditedMediaItems$
        .pipe(
          tap((result) => {
            this.loadEditedMediaItems(result);
          }),
        )
        .subscribe();
    this.usedStorageLeftMB$ = this.userConfig.userMetrics$.pipe(
      map((metrics) => {
        if ('usedStorage' in metrics && metrics.usedStorage) {
          return STORAGE_LIMIT - bytesToMB(metrics.usedStorage);
        } else {
          return 0;
        }
      }),
    );
    this.usedStoragePercentage$ = this.usedStorageLeftMB$.pipe(
      map((mbs) => {
        return ((STORAGE_LIMIT - mbs) / STORAGE_LIMIT) * 100;
      }),
    );
    this.initAsync();
  }

  async initAsync() {
    const user = await this.auth.getUser();
    this.userIsAdmin = user.role === 'admin';
    await Promise.allSettled([
      this.mediaService.getSectionsOrCreateSections(user.uid),
      this.mediaService.getGlobalSections(),
    ]).then((results) => {
      results.forEach((result) => {
        if (result.status == 'rejected') {
          console.error(result.reason);
        }
      });
      this.sections =
        results[0].status === 'fulfilled' ? results[0].value : null;
      this.globalSections =
        results[1].status === 'fulfilled' ? results[1].value : null;
    });
  }

  ngOnDestroy(): void {
    this.currentClientMediaItemsSubscription.unsubscribe();
    this.currentClientEditedMediaItemsSubscription.unsubscribe();
  }

  tryAutoSelectItem(): void {
    if (!this.data.autoSelectValue) {
      return;
    }
    if (this.data.autoSelectValue.type === 'original') {
      if (
        !this.currentClientMediaItems ||
        this.currentClientMediaItems.length === 0
      ) {
        return;
      }
      this.trySelectItemById(this.data.autoSelectValue.id);
    }

    if (this.data.autoSelectValue.type === 'edit') {
      if (
        !this.currentClientEditedMediaItems ||
        this.currentClientEditedMediaItems.length === 0
      ) {
        return;
      }
      this.trySelectEditedItemById(this.data.autoSelectValue.id);
    }
  }

  loadGlobalMediaItems(mediaItems: Array<MediaItem>) {
    this.globalMediaItems = mediaItems;
    this.isLoading = false;
    this.tryAutoSelectItem();
  }

  loadMediaItems(mediaItems: Array<MediaItem>) {
    this.currentClientMediaItems = mediaItems;
    if (this.currentClientMediaItems.length === 0) {
      this.noMediaItemsYet = true;
    } else {
      this.noMediaItemsYet = false;
    }
    this.isLoading = false;
    this.tryAutoSelectItem();
  }

  loadEditedMediaItems(mediaItems: Array<EditedMediaItem>) {
    this.currentClientEditedMediaItems = mediaItems;
    if (this.currentClientEditedMediaItems.length === 0) {
      this.noEditedMediaItemsYet = true;
    } else {
      this.noEditedMediaItemsYet = false;
    }
    this.isLoading = false;
    this.tryAutoSelectItem();
  }

  close() {
    history.back();
  }

  toggleHover(event: boolean) {
    this.isHoveringDropzone = event;
  }

  onDrop(files: FileList, isGlobal?: boolean) {
    this.filesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      this.filesToUpload.push(files.item(i));
    }
    this.mediaService.uploadFiles(this.filesToUpload, isGlobal);
  }

  handleOpenFile(event: any, isGlobal?: boolean) {
    this.onDrop(event.target.files, isGlobal);
  }

  test() {
    this.mediaService.currentClientMediaItems$.subscribe();
  }

  trySelectItemById(itemId: string) {
    const itemIndex = this.currentClientMediaItems.findIndex(
      (item) => item.id === itemId,
    );
    if (itemIndex === -1) {
      return;
    }
    this.selectItem(itemIndex);
  }

  trySelectEditedItemById(itemId: string) {
    const itemIndex = this.currentClientEditedMediaItems.findIndex(
      (item) => item.id === itemId,
    );
    if (itemIndex === -1) {
      return;
    }
    this.selectEditedItem(itemIndex);
  }

  selectItem(itemIndex: number, isGlobal?: boolean) {
    this.selectedItem = isGlobal
      ? this.globalMediaItems[itemIndex]
      : this.currentClientMediaItems[itemIndex];
    this.tabState = 'item';
    this.selectedItemType = 'original';
  }

  selectItemById(id: string, isGlobal?: boolean) {
    const index = isGlobal
      ? this.globalMediaItems.findIndex((m) => m.id === id)
      : this.currentClientMediaItems.findIndex((m) => m.id === id);
    this.selectItem(index, isGlobal);
  }

  selectEditedItem(itemIndex: number) {
    this.selectedItem = this.currentClientEditedMediaItems[itemIndex];
    this.tabState = 'item';
    this.selectedItemType = 'edited';
  }

  closeSelectedItem() {
    this.selectedItem = null;
    this.tabState = 'items';
  }

  openFileDialog(isGlobal?: boolean) {
    if (isGlobal) {
      document.getElementById('global-media-file-input').click();
    } else {
      document.getElementById('client-media-file-input').click();
    }
  }

  downLoadSelectedImage() {
    window.open(this.selectedItem.downloadURL);
  }

  async copySelectedImageURL() {
    if (this.copyURLState === 'copied') {
      return;
    }
    try {
      await navigator.clipboard.writeText(this.selectedItem.downloadURL);
      this.copyURLState = 'copied';
      await sleep(2000);
      this.copyURLState = 'default';
    } catch (error) {}
  }

  async downloadSelectedEditedImage() {
    this.renderingEditedImage = true;
    const result = await this.functions.call('renderEditedImageCallable', {
      id: this.selectedItem.id,
    });
    if (result) {
      window.open(result);
    }
    this.renderingEditedImage = false;
  }

  async editSelectedImage() {
    if (this.selectedItemType === 'original') {
      const result = await this.mediaEditService.openEditorFromOriginal(
        this.selectedItem,
      );
      this.selectedItem = result;
      this.selectedItemType = 'edited';
      this.tabState = 'item';
    } else {
      const result = await this.mediaEditService.openEditor(this.selectedItem);
      console.log('result', result);
      this.selectedItem = result as EditedMediaItem;
      this.editedImageView.item = this.selectedItem;
    }
  }

  async deleteSelectedFile() {
    await this.mediaService.deleteMediaItem(this.selectedItem);
    this.closeSelectedItem();
  }

  // create a function similar to the one above but that iterates over multiSelectList and deletes all of them

  async deleteSelectedFiles() {
    this.confirmActionDialogService.openDialog({
      title: `¿Estás seguro?`,
      description: `No podrás recuperar estas imágenes`,
      confirmButton: 'Eliminar',
      confirmCallback: async (confirm) => {
        if (confirm) {
          for (let i = 0; i < this.multiSelectList.length; i++) {
            const mediaItemId = this.multiSelectList[i];
            const refToLookInto =
              this.selectedItemsTab === 'Galería INTECC'
                ? this.globalMediaItems
                : this.currentClientMediaItems;
            const mediaItem = refToLookInto.find((m) => m.id === mediaItemId);
            await this.mediaService.deleteMediaItem(mediaItem);
          }
          this.multiSelectList = [];
          this.closeSelectedItem();
        }
      },
    });
  }

  async deleteEditedMediaItem() {
    await this.mediaEditService.deleteEditedMediaItem(this.selectedItem);
    this.closeSelectedItem();
  }

  toDataURL(url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }

  openCapture() {
    this.tabState = 'capture';
    this.captureState = 'capture';
    this.inclinometerService.startNoOffset(this);
    const front = false;
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: front ? 'user' : 'environment' },
        audio: false,
      })
      .then((stream) => {
        console.log('stream received');
        this.hookStream(stream);
      })
      .catch((err) => {
        console.log('An error occurred: ' + err);
      });
  }

  hookStream(stream) {
    this.videoStream = stream;
    this.videoElementRef.nativeElement.srcObject = stream;
    this.videoElementRef.nativeElement.play();
  }

  closeCapture() {
    this.inclinometerService.stop();
    this.tabState = 'items';
    this.captureState = 'capture';
    this.videoElementRef.nativeElement.pause();
    this.videoElementRef.nativeElement.srcObject = null;
    this.videoStream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  takePicture() {
    this.captureState = 'view';
    const context = this.canvas.nativeElement.getContext('2d');
    const width = this.videoElementRef.nativeElement.videoWidth;
    const height = this.videoElementRef.nativeElement.videoHeight;
    this.canvas.nativeElement.width = width;
    this.canvas.nativeElement.height = height;
    context.drawImage(this.videoElementRef.nativeElement, 0, 0, width, height);
    const data = this.canvas.nativeElement.toDataURL('image/png');
    this.renderer.setAttribute(
      this.photoViewElementRef.nativeElement,
      'src',
      data,
    );
  }

  async savePicture() {
    const data = this.canvas.nativeElement.toDataURL('image/png');
    const blob = dataURItoBlob(data);
    this.uploadingCapture = true;
    try {
      await this.mediaService.uploadFile(blob);
    } catch (error) {
      console.error(error);
    }
    this.uploadingCapture = false;
    this.closeCapture();
  }

  discardPicture() {
    this.captureState = 'capture';
  }

  returnSelectedImage() {
    const result: TestImageValue = {
      id: this.selectedItem.id,
      type: this.selectedItemType === 'original' ? 'original' : 'edit',
    };
    this.returnValue = result;
    this.close();
  }

  // SECTIONS --------------------------------
  addSection() {
    const sectionsRef =
      this.selectedItemsTab === 'original'
        ? this.sections
        : this.globalSections;
    if (!sectionsRef) {
      return;
    }
    if (!sectionsRef.sections) {
      sectionsRef.sections = [];
    }
    sectionsRef.sections.push({ name: 'Sección', mediaIds: [] });
    this.updateSections();
  }
  updateSections() {
    // TODO: this.updateProtocolsWihtoutSection();
    const sectionsRef =
      this.selectedItemsTab === 'original'
        ? this.sections
        : this.globalSections;

    this.mediaService.updateSections(sectionsRef);
  }
  getSectionMediaItemById(id: string, mediaItems: Array<MediaItem>) {
    const result = mediaItems.find((m) => m.id === id);
    return result;
  }
  getMediaWithoutSection(mediaItems: Array<MediaItem>) {
    const sectionsRef =
      this.selectedItemsTab === 'original'
        ? this.sections
        : this.globalSections;

    if (!sectionsRef || !sectionsRef.sections) {
      return mediaItems;
    }

    const result = mediaItems.filter(
      (m) =>
        !sectionsRef.sections.find(
          (section) => !!section.mediaIds.find((id) => id === m.id),
        ),
    );
    return result;
  }
  isMediaWithoutSection(
    mediatItem: MediaItem,
    mediatWithoutSection: Array<MediaItem>,
  ) {
    return !!mediatWithoutSection.find((m) => m.id === mediatItem.id);
  }

  isMediaItemIdInMediaItems(id: string, mediaItems: Array<MediaItem>) {
    const result = mediaItems.find((m) => m.id === id);
    return !!result;
  }

  handleTabChange(tab: 'original' | 'edited' | 'Galería INTECC') {
    this.multiSelectList = [];
    this.selectedItemsTab = tab;
  }

  openMoveMediaDialog(media: MediaItem, event) {
    const sectionRef =
      this.selectedItemsTab === 'original'
        ? this.sections
        : this.globalSections;
    event.stopPropagation();
    if (
      !sectionRef ||
      !sectionRef.sections ||
      sectionRef.sections.length === 0
    ) {
      return;
    }
    if (media) {
      this.selectedMediaToMove = media.id;
    }
    if (this.selectedItemsTab === 'original') {
      this.moveProtocolDialogOpen = true;
    } else {
      this.moveGlobalDialogOpen = true;
    }
  }

  multiSelectItem(media: MediaItem, event) {
    event.stopPropagation();
    if (this.multiSelectList.includes(media.id)) {
      const index = this.multiSelectList.indexOf(media.id);
      this.multiSelectList.splice(index, 1);
    } else {
      this.multiSelectList.push(media.id);
    }
    console.log(this.multiSelectList);
  }

  isMediaItemMultiSelected(media: MediaItem) {
    return this.multiSelectList.includes(media.id);
  }

  moveToSection(section: MediaSectionItem) {
    const mediaToMove =
      this.multiSelectList.length > 0
        ? this.multiSelectList
        : [this.selectedMediaToMove];
    if (this.moveProtocolDialogOpen) {
      this.sections.sections.forEach((s) => {
        s.mediaIds = s.mediaIds.filter((id) => !mediaToMove.includes(id));
      });
      this.moveProtocolDialogOpen = false;
    } else if (this.moveGlobalDialogOpen) {
      this.globalSections.sections.forEach((s) => {
        s.mediaIds = s.mediaIds.filter((id) => !mediaToMove.includes(id));
      });
      this.moveGlobalDialogOpen = false;
    }
    section.mediaIds.push(...mediaToMove);
    this.multiSelectList = [];
    this.updateSections();
  }

  moveToSectionMulti(section: MediaSectionItem, selectedMediaToMove: string[]) {
    this.sections.sections.forEach((s) => {
      s.mediaIds = s.mediaIds.filter((id) => !selectedMediaToMove.includes(id));
    });
    section.mediaIds.push(...selectedMediaToMove);
    this.moveProtocolDialogOpen = false;
    this.updateSections();
  }

  closeMoveToSection() {
    this.moveProtocolDialogOpen = false;
  }

  closeGlobalMoveToSection() {
    this.moveGlobalDialogOpen = false;
  }

  deleteSection(index: number) {
    const sectionRef =
      this.selectedItemsTab === 'original'
        ? this.sections
        : this.globalSections;

    if (!sectionRef) return;

    sectionRef.sections.splice(index, 1);
    this.updateSections();
  }

  openRenameSection(section: MediaSectionItem, event) {
    event.stopPropagation();
    this.editSectionNameOpen = true;
    this.editSectionNameSection = section;
    this.editSectionNameText = section.name;
  }

  moveSectionUp(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === 0) {
      return;
    }
    const hold = this.sections.sections[sectionIndex - 1];
    this.sections.sections[sectionIndex - 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveSectionDown(sectionIndex: number, event) {
    event.stopPropagation();
    if (sectionIndex === this.sections.sections.length - 1) {
      return;
    }
    const hold = this.sections.sections[sectionIndex + 1];
    this.sections.sections[sectionIndex + 1] =
      this.sections.sections[sectionIndex];
    this.sections.sections[sectionIndex] = hold;
    this.updateSections();
  }

  moveProtocolUp(sectionIndex: number, imageIndex: number, event) {
    event.stopPropagation();

    const section =
      this.selectedItemsTab === 'original'
        ? this.sections.sections[sectionIndex].mediaIds
        : this.globalSections.sections[sectionIndex].mediaIds;
    console.log(section);

    if (imageIndex === 0) {
      return;
    }
    const hold = section[imageIndex - 1];
    section[imageIndex - 1] = section[imageIndex];
    section[imageIndex] = hold;
    this.updateSections();
  }

  moveProtocolDown(sectionIndex: number, imageIndex: number, event) {
    event.stopPropagation();
    const section =
      this.selectedItemsTab === 'original'
        ? this.sections.sections[sectionIndex].mediaIds
        : this.globalSections.sections[sectionIndex].mediaIds;
    if (imageIndex === section.length - 1) {
      return;
    }
    const hold = section[imageIndex + 1];
    section[imageIndex + 1] = section[imageIndex];
    section[imageIndex] = hold;
    this.updateSections();
  }

  endRenameSection() {
    this.editSectionNameSection.name = this.editSectionNameText;
    this.editSectionNameOpen = false;
    this.updateSections();
  }

  // SECTIONS END ----------------------------
}

export interface ClientMediaModalData {
  client: Client;
  onlyOriginals?: boolean;
  selectionMode?: boolean;
  selectionName?: string;
  autoSelectValue?: TestImageValue;
}
