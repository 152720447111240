<div
  class="sm-backdrop z-[1001]"
  [class.sm-backdrop--is-open]="isOpen"
  (click)="onBackdropClick($event)"
>
  <div
    class="sm-content sm-content--bottom"
    [class.sm-content--is-open]="isOpen"
  >
    <ng-template appModalHost></ng-template>
  </div>
</div>
